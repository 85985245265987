<template>
  <div>
    <div class="flex justify-between">
      <input v-model="search" type="text" placeholder="Search User Or Resource" class="w-1/2" />
    </div>
    <ul class="flex mt-10">
      <li
        v-for="m in modes"
        :key="m"
        @click="mode = m"
        class="border-b pb-3 px-5 cursor-pointer font-semibold"
        :class="m == mode ? 'text-blue-400 border-blue-400' : 'text-gray-600 border-gray-600'"
      >
        {{ m }}
      </li>
    </ul>
    <div class="mt-10 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-700 font-semibold">
            <thead class="bg-gray-900">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  User
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  Action
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  Resource
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  Data
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    whitespace-no-wrap
                    text-left text-xs text-gray-300
                    bg-transparent
                    uppercase
                    tracking-wider
                  "
                >
                  Created At
                </th>
                <th
                  v-if="mode == 'Pending'"
                  scope="col"
                  class="
                    px-6
                    py-3
                    whitespace-no-wrap
                    text-left text-xs text-gray-300
                    bg-transparent
                    uppercase
                    tracking-wider
                  "
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(action, ix) in filteredActions"
                :key="action.id"
                :class="ix % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'"
                class="mt-10"
              >
                <td class="px-6 py-2 text-xs">
                  {{ action.user.email }}
                </td>
                <td class="px-6 py-2 text-xs text-left capitalize">
                  {{ action.action }}
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  {{ action.resource_class }}
                </td>
                <td class="px-6 py-2 text-xs">
                  {{ action.params }}
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  <span>{{ action.created_at | relativeTime }}</span>
                </td>
                <td v-if="mode == 'Pending'" class="px-6 py-2 whitespace-no-wrap text-xs">
                  <span
                    v-if="action.resource_class == 'Tag'"
                    @click="setDataAndShowTagModal(action)"
                    class="text-blue-400 cursor-pointer"
                    >Test Tag</span
                  >
                  <span
                    v-if="action.resource_class == 'Source' && !nonSchedulableModes.includes(action.params.mode)"
                    @click="setDataAndShowSourceModal(action)"
                    class="text-blue-400 cursor-pointer"
                    >Test Scrape</span
                  >
                  <span
                    v-if="action.resource_class == 'ModelGroup'"
                    @click="setDataAndShowModelGroupModal(action)"
                    class="text-blue-400 cursor-pointer"
                    >Test Model</span
                  >
                </td>
                <td v-if="mode == 'Pending'" class="px-6 py-2 text-center">
                  <div class="flex space-x-4">
                    <div
                      @click="approveAction(action.id)"
                      class="
                        bg-green-600
                        hover:bg-green-500
                        p-3
                        rounded
                        font-semibold
                        text-sm text-center
                        cursor-pointer
                      "
                    >
                      Approve
                    </div>
                    <button
                      class="text-btn text-btn-red font-semibold text-sm text-center cursor-pointer"
                      @click="deleteUserAction(action.id)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <TestTagModal
            :show="showTestTagModal"
            @close="showTestTagModal = false"
            :action="selectedAction"
          ></TestTagModal>
          <TestSourceModal
            :show="showTestSourceModal"
            @close="showTestSourceModal = false"
            :action="selectedAction"
          ></TestSourceModal>
          <TestModelGroupModal
            :show="showTestModelGroupModal"
            @close="showTestModelGroupModal = false"
            :action="selectedAction"
          ></TestModelGroupModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import TestTagModal from '../modals/TestTagModal';
import TestSourceModal from '../modals/TestSourceModal';
import TestModelGroupModal from '../modals/TestModelGroupModal';
export default {
  components: {
    TestTagModal,
    TestSourceModal,
    TestModelGroupModal
  },
  mounted() {
    this.fetchActions();
  },
  data() {
    return {
      pendingActions: [],
      approvedActions: [],
      search: '',
      mode: 'Pending',
      modes: ['Pending', 'Approved'],
      showTestTagModal: false,
      showTestSourceModal: false,
      showTestModelGroupModal: false,
      nonSchedulableModes: ['Twitter', 'Email'],
      selectedAction: {}
    };
  },
  methods: {
    async fetchActions() {
      let response = await axios.get('/user_actions');
      this.pendingActions = response.data.pending_actions;
      this.approvedActions = response.data.approved_actions;
    },
    async approveAction(action_id) {
      let response = await axios.patch('/user_actions/' + action_id + '/approve');
      this.pendingActions = this.pendingActions.filter(x => x.id !== response.data.user_action.id);
      this.approvedActions.push(response.data.user_action);
    },
    async deleteUserAction(id) {
      await this.$dialog.confirm('Are you sure?');
      await axios.delete(`/user_actions/${id}`);
      this.pendingActions.splice(
        this.pendingActions.findIndex(v => v.id === id),
        1
      );
    },
    setDataAndShowTagModal(action) {
      this.showTestTagModal = true;
      this.selectedAction = action;
    },
    setDataAndShowSourceModal(action) {
      this.showTestSourceModal = true;
      this.selectedAction = action;
    },
    setDataAndShowModelGroupModal(action) {
      this.showTestModelGroupModal = true;
      this.selectedAction = action;
    }
  },
  computed: {
    filteredActions() {
      let actions = this.mode === 'Pending' ? this.pendingActions : this.approvedActions;
      return actions?.filter(s => {
        return (
          this.search == '' ||
          s.user.email.toLowerCase().includes(this.search.toLowerCase()) ||
          s.resource_class.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  },
  filters: {
    relativeTime(t) {
      if (t) {
        return moment(t).fromNow();
      }
      return null;
    }
  }
};
</script>
