<template>
  <BaseModal :show="show" max-width="lg" @close="close">
    <template #body>
      <button @click="testSource(action)" class="bg-green-600 hover:bg-green-500 duration-200 px-3 py-2 rounded cursor-pointer text-sm font-semibold text-center w-full">Run a Test Scrape (Do This First!)</button>
      <div v-if="ranATestScrape" class="w-full mt-10">
        <span v-if="loading" class="font-semibold">Scraping...</span>
        <div v-else class="bg-gray-800 rounded">
          <p class="font-bold p-5 border-b border-gray-700">Test Scrape ({{ articles.length }} Headlines)</p>
          <div style="max-height: 600px" class="overflow-y-auto p-5 flex flex-col gap-y-3">
            <p v-for="article in articles" :key="article.id">
              <a :href="article.link" class="font-semibold text-sm" target="_blank">{{ article.headline }}</a>
              <span v-if="article.date" class="text-sm text-gray-300">
                <br>
                {{ article.date | createdAt }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import axios from 'axios'
import BaseModal from './BaseModal'
export default {
  name: 'TestSourceModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    action: {
      type: Object
    }
  },
  components: {
    BaseModal
  },
  data() {
    return {
      ranATestScrape: false,
      articles: [],
      loading: false
    }
  },
  methods: {
    testSource(action) {
      this.loading = true
      this.ranATestScrape = true
      axios
      .post("/web-scrape", { 
        url: action.params.url,
        mode: action.params.mode,
        headline_path: action.params.headline_path,
        link_path: action.params.link_path,
        date_path: action.params.data_path,
        date_format: action.params.date_format,
        translate: action.params.translate,
        ciks: action.params.ciks,
        custom_class: action.params.custom_class })
      .then(response => {
        this.articles = response.data.articles
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
    },
    close() {
      this.action = {}
      this.articles = []
      this.showTestSourceModal = false
      this.ranATestScrape = false
      this.$emit('close')
    },
  }
}
</script>
