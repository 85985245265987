<template>
  <notification-group group="app">
    <div class="w-full sm:max-w-md fixed top-5 md:top-auto md:right-0 items-center justify-center z-50">
      <notification v-slot="{ notifications, close }">
        <template v-for="notification in notifications">
          <Flash :key="notification.id" :notification="notification" :close="close" />
        </template>
      </notification>
    </div>
  </notification-group>
</template>
<script>
import Flash from './flash';
export default {
  components: {
    Flash
  },
  props: ['flashMessages'],
  mounted() {
    const self = this;
    if (self.flashMessages.length > 0) {
      self.flashMessages.forEach(msg => {
        if (msg[1] !== '') {
          self.$notify(
            {
              group: 'app',
              type: 'flash',
              text: {
                type: msg[0],
                message: msg[1]
              }
            },
            5000
          );
        }
      });
    }
  }
};
</script>
