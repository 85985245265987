<template>
  <div class="">
    <div class="flex flex-row justify-between">
      <div class="flex space-x-3">
        <form class="flex space-x-4">
          <input type="text" v-model="search" placeholder="Search a headline" label="Search" />
        </form>
        <select v-model="selectedSource" class="form-select py-1 text-sm w-64 bg-gray-800 border-0">
          <option :value="0" disabled selected>Select Source</option>
          <option v-for="source in sources" :value="source.id" :key="source.id">{{ source.name }}</option>
        </select>
        <date-picker
          v-model="selectedDate"
          range
          value-type="format"
          input-class="text-white text-sm w-full"
          placeholder="Start Date ~ End Date"
        ></date-picker>
      </div>
      <div>
        <div
          v-if="editableItems && editableItems.length < 50 && totalPages > 1"
          @click="pageChange(page - 1, totalPages)"
          class="ml-2 px-2 py-2 rounded cursor-pointer text-white bg-blue-600"
        >
          Previous Page
        </div>
        <div
          v-if="editableItems && editableItems.length >= 50 && totalPages > 1"
          @click="pageChange(page + 1, totalPages)"
          class="ml-2 px-2 py-2 rounded cursor-pointer text-white bg-blue-600"
        >
          Next Page
        </div>
      </div>
    </div>
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-3 lg:px-8">
        <div class="shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-700 font-semibold text-xs table-fixed">
            <thead class="bg-gray-900">
              <tr>
                <th
                  scope="col"
                  class="
                    px-3
                    py-3
                    text-left text-xs text-gray-300
                    bg-transparent
                    uppercase
                    tracking-wider
                    whitespace-no-wrap
                  "
                  v-for="(column, id) in columns"
                  :key="id"
                >
                  <span v-if="sortedColumn === id">
                    <svg
                      v-if="sortOrder === 'asc'"
                      class="w-4 h-4 flex-shrink-0 mr-1 inline"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <svg v-else class="w-4 h-4 flex-shrink-0 mr-1 inline" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span v-if="column.name === 'Source' || column.name === 'Tags'" class="cursor-auto">{{
                    column.name
                  }}</span>
                  <span v-else class="cursor-pointer" @click="sort(editableItems, id)">{{ column.name }}</span>
                </th>
                <th v-if="userRole == 'admin'" scope="col" class="px-3 py-3 bg-transparent"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, itemIdx) in editableItems"
                :key="item.id"
                :class="
                  item.public
                    ? itemIdx % 2 == 0
                      ? 'bg-gray-800'
                      : 'bg-gray-900'
                    : 'bg-red-900 bg-opacity-50 text-red-200'
                "
              >
                <td class="text-left px-3 py-3 break-all">
                  <span
                    v-if="item.initial_scrape"
                    class="px-2 py-1 mr-2 text-xs bg-red-600 text-white font-bold rounded w-5/12"
                    >1st Scrape</span
                  >
                  <a :href="item.link" class="font-semibold text-sm" target="_blank">{{
                    item.translated_headline || item.headline
                  }}</a>
                  <a v-if="item.translated_headline" :href="item.link" class="text-xs" target="_blank"
                    ><br />{{ item.headline }}</a
                  >
                </td>
                <td class="text-left px-3 py-3 break-all w-2/12">
                  {{ item.tags.map(tag => tag.name).join(', ') }}
                </td>
                <td class="text-left px-3 py-3">
                  <span>{{ item.sources.map(i => i.name).join(', ') }}</span>
                </td>
                <td class="px-3 whitespace-no-wrap">
                  <span v-if="item.date">{{ item.date | timestamp }}</span>
                </td>
                <td class="px-3 whitespace-no-wrap w-1/12">
                  {{ item.timestamp | timestamp }}
                </td>
                <td v-if="userRole == 'admin'" class="px-3">
                  <span v-if="item.public" @click="hideItem(item)" class="cursor-pointer text-red-600">Hide</span>
                  <span v-else @click="unhideItem(item)" class="cursor-pointer text-white">Unhide</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import tableMixin from '../../mixins/table';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  mixins: [tableMixin],
  components: {
    DatePicker
  },
  props: {
    currentTeam: {
      type: Number,
      default: null
    },
    userRole: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editableItems: [],
      search: '',
      awaitingSearch: false,
      page: 1,
      per_page: 50,
      query_total: this.total_items,
      search_delay: 500, // change this to reduce the number of requests sent to server
      sources: [],
      selectedSource: 0,
      selectedDate: null,
      columns: {
        headline: { name: 'Headline', align: 'text-left' },
        tags: { name: 'Tags', align: 'text-left' },
        sources: { name: 'Source', align: 'text-left' },
        date: { name: 'Date', align: 'text-left' },
        timestamp: { name: 'Timestamp', align: 'text-left' }
      }
    };
  },
  watch: {
    search() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.pageChange(1, -1);
      }, this.search_delay);
    },
    selectedSource() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.pageChange(1, -1);
      }, this.search_delay);
    },
    selectedDate() {
      this.pageChange(1, -1);
    }
  },
  mounted() {
    this.searchHeadlines();
    this.getSources();
    this.subscribeToUpdates();
  },
  methods: {
    async searchHeadlines() {
      const response = await axios.get('/parsed_items', {
        params: {
          search: this.search,
          source_id: this.selectedSource !== 0 ? this.selectedSource : null,
          start_datetime: this.selectedDate ? this.selectedDate[0] : null,
          end_datetime: this.selectedDate ? this.selectedDate[1] : null,
          page: this.page,
          per_page: this.per_page
        }
      });
      this.editableItems = response.data.all_data.data;
      this.query_total = response.data.all_data.total_items;
    },
    async hideItem(item) {
      await axios.patch('/parsed_items/' + item.id, { public: false });
      item.public = false;
    },
    async unhideItem(item) {
      await axios.patch('/parsed_items/' + item.id, { public: true });
      item.public = true;
    },
    async pageChange(idx, total_pages) {
      if (((idx >= 1) & (idx <= total_pages)) | (total_pages == -1)) {
        this.page = idx;
        this.searchHeadlines();
      }
    },
    async getSources() {
      let response = await axios.get('/sources.json', {
        params: {
          all_sources: true
        }
      });
      this.sources = [{ id: 0, name: 'None' }, ...response.data.all_data.data];
    },
    subscribeToUpdates() {
      const self = this;
      self.$root.store.cable.subscriptions.create(
        { channel: 'ParsedItemChannel', team_id: this.currentTeam },
        {
          connected() {},
          received(data) {
            let headline = data['translated_headline'] || data['headline'];
            if (self.search !== '' && !headline.includes(self.search)) {
              return;
            }
            var present = self.editableItems.filter(function (item) {
              if (item.link_hash == data.link_hash && item.team_id == data.team_id) {
                item.sources = item.sources.concat(data.sources);
                item.timestamp = data.timestamp;
                item.date = data.date || data.timestamp;
                item.tags = item.tags.concat(data.tags);
                //remove dup
                item.tags = Array.from(new Set(item.tags));
                return item;
              }
            });
            if (present.length == 0) self.editableItems.unshift(data);
          }
        }
      );
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.query_total / this.per_page);
    }
  },
  filters: {
    timestamp(value) {
      return moment(value).format('Y-MM-DD H:mm:ss');
    }
  }
};
</script>
