<template>
  <div class="cursor-pointer text-white" @click="toggleDropdown()" :id="this.dropdownId">
    <span class="text-white"> {{ name }} ▾ </span>
    <div class="relative text-left z-50">
      <Basedropdown v-if="droppedDown" :items="items" :link-items="true" />
    </div>
  </div>
</template>

<script>
import Basedropdown from './BaseDropdown';
export default {
  name: 'adminActionsDropdown',
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  components: {
    Basedropdown
  },
  props: {
    name: String
  },
  methods: {
    close: function (e) {
      const drop = document.getElementById(this.dropdownId);
      if (!drop.contains(e.target) && this.droppedDown && e.target.id !== this.dropdownId) {
        this.droppedDown = false;
      }
    },
    toggleDropdown: function () {
      this.droppedDown = !this.droppedDown;
    }
  },
  data: function () {
    return {
      dropdownId: `admin_action_dropdown_${Math.random()}`,
      droppedDown: false,
      items: [
        { link: '/sidekiq', text: 'Background Jobs' },
        { link: '/user_actions', text: 'Pending Approvals' },
        { link: '/backfill', text: 'Backfill News' }
      ]
    };
  }
};
</script>
