<template>
  <div v-if="budget" :class="overBudget ? 'bg-red-900 text-red-100' : 'bg-gray-800 text-white'" class="text-center mb-8 p-4 rounded max-w-lg mx-auto transition-colors duration-200">
    <span class="font-semibold">Twitter Budget</span>
    <div class="">
      <span class="text-xl font-semibold">{{ budgetRemaining.toLocaleString('en', { maximumFractionDigits: 0 }) }}</span>
      <span class="text-sm text-gray-300">requests remaining out of</span>
      <span class="text-xl font-semibold">{{ budget.budget }}</span>
      <span class="text-sm text-gray-300">per 15 minutes</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['editSchedulerValue', 'editSchedulerInterval', 'schedulerValue', 'schedulerInterval'],
  data () {
    return {
      budget: null
    }
  },
  mounted () {
    this.getBudget()
  },
  methods: {
    getBudget() {
      axios.get("/twitter_budgets").then(response => this.budget = response.data).catch(error => console.log(error))
    },
  },
  computed: {
    overBudget() {
      if (this.budget == null) { return false }
      var result = this.budgetRemaining < 0
      this.$emit('budgetChange', result)
      return result
    },
    budgetRemaining() {
      if (this.budget == null) { return 0 }
      const intervalToMinutes = {
        seconds: 60,
        minutes: 1,
        hours: 1.0 / 60
      }
      return this.budget.budget_remaining - (intervalToMinutes[this.schedulerInterval] / this.schedulerValue * 15) + (this.editSchedulerValue ? (intervalToMinutes[this.editSchedulerInterval] / this.editSchedulerValue * 15) : 0)
    }
  }
}
</script>
