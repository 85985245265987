<template>
  <div
    class="bg-gray-900 text-white z-30 select-none rounded shadow"
    :class="[width, `${xAnchor}-0`, useParentContainer ? '' : 'absolute']"
    style="min-width: 200px"
  >
    <slot />
    <template v-if="linkItems">
      <a
        :href="row.link"
        v-for="(row, idx) in items"
        :key="idx"
        :target="row.newTab ? '_blank' : ''"
        class="
          block
          py-2
          text-md
          px-3
          select-none
          text-gray-300
          hover:text-white
          bg-gray-900
          hover:bg-gray-700
          capitalize
        "
      >
        {{ row.text }}
      </a>
    </template>
    <template v-else>
      <div
        v-for="(row, idx) in items"
        :key="idx"
        @click="clicked(row.id)"
        :class="`
          ${row.class}
          text-left
          block
          py-2
          text-md
          px-3
          select-none
          text-gray-300
          hover:text-white
          bg-gray-900
          hover:bg-gray-700
          capitalize
        `"
      >
        {{ row.text }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    xAnchor: {
      type: String,
      default: 'right'
    },
    linkItems: Boolean,
    width: {
      type: String,
      default: 'w-full'
    },
    useParentContainer: Boolean
  },
  methods: {
    clicked: function (item) {
      this.$emit('clicked', item);
    }
  }
};
</script>
