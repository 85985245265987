<template>
  <BaseModal :show="show" max-width="2xl" @close="close">
    <template #body>
      <div class="flex flex-col">
        <h2 class="font-bold text-lg mb-2">Revisions</h2>
        <div class="space-y-2" v-if="versions.length">
          <div v-for="version in versions" :key="version.id" class="rounded bg-gray-800 p-2">
            <div class="mb-2">
              Performed by: <span class="text-sm">{{ version.user.email }}</span>
            </div>
            <div class="mb-1">
              Performed at: <span class="text-sm">{{ version.created_at | createdAt }}</span>
            </div>
            <div class="mb-4">
              <div v-for="(change, key) in version.changes" :key="key" class="space-y-2">
                <div>
                  Change:
                  <div v-html="diffString(change[0], change[1])"></div>
                </div>
                <div>
                  <span class="font-bold text-gray-400">Diff:</span>
                  <span v-html="diffString(change[0], change[1])" class="text-sm"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <span class="text-gray-400 text-sm flex items-center justify-center w-full h-20 font-semibold">
            No Revisions Found.
          </span>
        </div>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import axios from 'axios';
import BaseModal from './BaseModal';
import moment from 'moment';
const Diff = require('diff');
export default {
  name: 'TestTagModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    resource: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BaseModal
  },
  data() {
    return {
      versions: []
    };
  },
  mounted() {
    this.getVersions();
  },
  methods: {
    close() {
      this.versions = [];
      this.$emit('close');
    },
    getVersions() {
      if (this.resource.id !== '') {
        axios.get('/revisions', { params: { ...this.resource } }).then(response => {
          this.versions = response.data;
        });
      }
    },
    diffString(oldVal, newVal) {
      const diffResult = Diff.diffChars(oldVal, newVal);
      const diffHtml = diffResult
        .map(part => {
          if (part.added) {
            return `<span class="bg-green-700">${part.value == ' ' ? '&nbsp;' : part.value}</span>`;
          } else if (part.removed) {
            return `<span class="bg-red-800">${part.value == ' ' ? '&nbsp;' : part.value}</span>`;
          }
          return part.value;
        })
        .join('');
      return diffHtml;
    }
  },
  filters: {
    createdAt(value) {
      return moment(value).format('Y-MM-DD H:mm:ss');
    }
  }
};
</script>
