<template>
  <button
    @click="onclick"
    class="flex items-center justify-center border-0 outline-none focus:outline-none rounded"
    :class="[sizeClasses, colorClasses, hoverClasses, disabled ? 'bg-opacity-50 cursor-not-allowed' : 'bg-opacity-100']"
  >
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: 'md'
    },
    type: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prevent: { type: Boolean, default: false } // For preventing default click behavior.
  },
  computed: {
    sizeClasses() {
      return {
        sm: 'px-4 py-2 text-xs',
        md: 'px-6 py-2 text-sm',
        lg: 'px-8 py-2 text-md',
        xl: 'px-10 py-2 text-lg'
      }[this.size];
    },
    colorClasses() {
      return {
        primary: 'bg-blue-600',
        secondary: 'bg-gray-800 text-gray-200',
        danger: 'bg-red-1200 text-gray-400'
      }[this.type];
    },
    hoverClasses() {
      if (!this.disabled) {
        return {
          primary: 'hover:bg-blue-500',
          secondary: 'hover:bg-gray-700 hover:text-gray-100',
          danger: 'hover:bg-red-900 hover:text-white'
        }[this.type];
      }
      return '';
    }
  },
  methods: {
    onclick($event) {
      if (!this.disabled) {
        if (this.prevent) $event.preventDefault();
        this.$emit('click');
      }
    }
  }
};
</script>
