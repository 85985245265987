<template>
  <nav class="bg-gray-800">
    <div class="container mx-auto">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center w-full">
          <div class="flex-shrink-0">
            <img src="../images/the_tie_logo_white.svg" class="block h-8 w-auto" />
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex">
              <a
                v-for="(link, idx) in links"
                :key="idx"
                :href="link.link"
                class="
                  px-3
                  py-2
                  rounded-md
                  text-sm
                  font-medium
                  leading-5
                  text-white
                  focus:outline-none focus:text-white focus:bg-gray-700
                  transition
                  duration-150
                  ease-in-out
                "
                :class="currentLink == link.link ? 'bg-gray-900' : ''"
                >{{ link.name }}</a
              >
            </div>
          </div>
          <div class="ml-auto flex">
            <a
              href="/settings"
              class="
                px-3
                py-2
                rounded-md
                text-sm
                font-medium
                leading-5
                text-gray-300
                hover:text-white hover:bg-gray-700
                focus:outline-none focus:text-white focus:bg-gray-700
                transition
                duration-150
                ease-in-out
              "
              >Settings</a
            >
            <a
              href="/teams"
              class="
                px-3
                py-2
                rounded-md
                text-sm
                font-medium
                leading-5
                text-gray-300
                hover:text-white hover:bg-gray-700
                focus:outline-none focus:text-white focus:bg-gray-700
                transition
                duration-150
                ease-in-out
              "
              >Teams
              <span class="px-2 py-1 rounded-full bg-green-600 text-xs font-semibold">{{
                store.currentTeam ? store.currentTeam.name : ''
              }}</span></a
            >

            <div
              v-if="$root.store.userRole == 'admin'"
              class="
                px-3
                py-2
                rounded-md
                text-sm
                font-medium
                leading-5
                text-gray-300
                hover:text-white hover:bg-gray-700
                focus:outline-none focus:text-white focus:bg-gray-700
                transition
                duration-150
                ease-in-out
              "
            >
              <AdminActionsDropdown :name="actionName" />
            </div>
            <a
              id="sign_out"
              class="
                px-3
                py-2
                rounded-md
                text-sm
                font-medium
                leading-5
                text-gray-300
                hover:text-white hover:bg-gray-700
                focus:outline-none focus:text-white focus:bg-gray-700
                transition
                duration-150
                ease-in-out
                cursor-pointer
              "
              @click="signOutUser"
              >Sign Out</a
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios';
import AdminActionsDropdown from './dropdowns/AdminActionsDropdown.vue';
export default {
  props: {
    userRole: {
      type: String,
      default: ''
    }
  },
  components: {
    AdminActionsDropdown
  },
  mounted() {
    this.$root.store.userRole = this.userRole;
  },
  data() {
    return {
      store: this.$root.store,
      links: [
        { name: 'Home', link: '/' },
        { name: 'Collections', link: '/collections' },
        { name: 'Sources', link: '/sources' },
        { name: 'Twitter', link: '/twitter_queries' },
        { name: 'Tags', link: '/tags' },
        { name: 'Parsed Items', link: '/parsed_items' }
        // { name: "Tags", link: "/tags" },
      ],
      currentLink: window.location.pathname,
      actionName: 'Admin Actions'
    };
  },
  methods: {
    signOutUser() {
      axios.delete('/users/sign_out').then(() => window.location.reload());
    }
  }
};
</script>
