export default {
  data () {
    return {
      sortColumn: 'id',
      columnDirection: 'desc',
      sortedColumn: '',
      sortOrder: '',
    }
  },
  methods: {
    sortByAttribute (arrayOfObjects, attribute, order = 'asc', sentiment = false) {
      if (order === 'asc') {
        return arrayOfObjects.slice().sort((obj1, obj2) => {
          if (isNaN(parseFloat(obj1[attribute]))) {   //string sort
            return obj1[attribute] > obj2[attribute] ? 1 : -1
          }
          else {
            return parseFloat(obj1[attribute]) > parseFloat(obj2[attribute]) ? 1 : -1
          }
        })
      } else if (order === 'desc') {
        // console.log("----------------------------------------------",arrayOfObjects.slice().filter((obj) => obj[attribute] != null));
        return arrayOfObjects.slice().sort((obj1, obj2) => {
          if (isNaN(parseFloat(obj1[attribute]))) {     //string sort

            return obj1[attribute] > obj2[attribute] ? -1 : 1
          }
          else {
            return parseFloat(obj1[attribute]) > parseFloat(obj2[attribute]) ? -1 : 1
          }
        })
      }
    },
    toggleColumnSort (attribute) {
      var columnDirections = ['desc', 'asc']
      if (this.sortColumn !== attribute) {
        this.columnDirection = 'desc'
      } else {
        // wrap around available column directions
        var currentDirectionIndex = columnDirections.findIndex((direction) => direction === this.columnDirection)
        this.columnDirection = columnDirections[(currentDirectionIndex + 1) % columnDirections.length]
      }
      this.sortColumn = attribute
    },
    sortByColumn(column) {
      if (column === this.sortedColumn) {
          this.sortOrder = (this.sortOrder === 'asc') ? 'desc' : 'asc'
      } else {
          this.sortedColumn = column
          this.sortOrder = 'asc'
      }
    },
    sort(arr, column){
      this.sortByColumn(column)

      if (this.sortOrder == 'asc') {
        if (isNaN(parseFloat(arr[0][column])))
          arr.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? 1 : -1)
        else
          arr.sort((a, b) => ((a[column]) > (b[column])) ? 1 : -1) 
      }
      else if(this.sortOrder == 'desc'){
        if (isNaN(parseFloat(arr[0][column])))
          arr.sort((a, b) => (String(a[column]).toLowerCase() > String(b[column]).toLowerCase()) ? -1 : 1)
        else
          arr.sort((a, b) => (a[column] > b[column]) ? -1 : 1)
      }
    },
  },
  
  computed: {
    sortedSources () {
      return this.sortByAttribute(this.editableSources, this.sortColumn, this.columnDirection)
    }
  }
}
