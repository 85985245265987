<template>
  <BaseModal :show="show" max-width="lg" @close="close">
    <template #body>
      <textarea required rows="8" class="w-full mt-2 text-sm bg-gray-800 border-0 form-textarea" v-model="action.params.rule" placeholder='r"price analysis", r"analyst predicts"'></textarea>
      <input type="text" class="w-full text-sm form-input" v-model="testHeadline" placeholder="Test a headline">
      <input type="text" class="w-full mt-2 text-sm form-input" v-model="testURL" placeholder="Test a URL">
      <div class="flex items-center mt-2">
        <div v-if="testResult.length > 0">
          <span class="text-sm text-gray-600">Score:</span> <span class="text-lg font-bold">{{ testResult[0].score }}</span>
        </div>
        <span @click="testModel" class="ml-auto text-sm font-semibold text-blue-400 cursor-pointer">Test model</span>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import axios from 'axios'
import BaseModal from './BaseModal'
export default {
  name: 'TestModelGroupModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    action: {
      type: Object
    }
  },
  components: {
    BaseModal
  },
  data() {
    return {
      testHeadline: "",
      testURL: "",
      testResult: []
    }
  },
  methods: {
    close() {
      this.testHeadline = ""
      this.testURL = ""
      this.rule = ""
      this.testResult = []
      this.$emit('close');
    },  
    testModel() {
      axios
      .post("/tag-model-test", { url: this.testURL, headline: this.testHeadline, tag_model: "1: " + this.action.params.rule.trim() })
      .then(response => {
        this.testResult = response.data.data
      })
    }
  }
}
</script>
