<template>
  <div class="flex flex-col">
    <span class="font-semibold">
      {{ activeCollection.id > 0 ? 'Editing ' + activeCollection.name : 'Create a Collection' }}
    </span>
    <form
      @submit.prevent="activeCollection.id > 0 ? updateCollection(activeCollection.id) : createCollection()"
      class="flex items-center space-x-5 max-w-2xl"
    >
      <div class="w-full">
        <div class="mt-1 relative rounded-md shadow-sm text-sm">
          <input
            required
            id="name"
            v-model="activeCollection.name"
            type="text"
            class="w-full"
            placeholder="Direct from Crypto Projects"
          />
        </div>
      </div>
      <div class="flex-shrink-0">
        <label for="checkbox" class="block text-sm leading-5 text-gray-200 font-semibold">
          <input type="checkbox" id="checkbox" v-model="activeCollection.public" class="form-checkbox text-lg" />
          Public?
        </label>
      </div>
      <primaryButton
        :text="activeCollection.id > 0 ? 'Save Collection' : 'Create Collection'"
        class="self-end w-64"
      ></primaryButton>
    </form>
    <div class="mt-10 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-700 font-semibold">
            <thead class="bg-gray-900">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  <span @click="sort(collections, 'name')" class="cursor-pointer inline">
                    <span v-if="sortedColumn === 'name'">
                      <svg
                        v-if="sortOrder === 'asc'"
                        class="w-4 h-4 flex-shrink-0 mr-1 inline"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <svg v-else class="w-4 h-4 flex-shrink-0 mr-1 inline" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fill-rule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg> </span
                    >Name
                  </span>
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-right text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  <span @click="sort(collections, 'num_sources')" class="cursor-pointer">
                    <span v-if="sortedColumn === 'num_sources'">
                      <svg
                        v-if="sortOrder === 'asc'"
                        class="w-4 h-4 flex-shrink-0 mr-1 inline"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <svg v-else class="w-4 h-4 flex-shrink-0 mr-1 inline" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fill-rule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    # Sources
                  </span>
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-center text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  Public?
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  <span @click="sort(collections, 'created_at')" class="cursor-pointer">
                    <span v-if="sortedColumn === 'created_at'">
                      <svg
                        v-if="sortOrder === 'asc'"
                        class="w-4 h-4 flex-shrink-0 mr-1 inline"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <svg v-else class="w-4 h-4 flex-shrink-0 mr-1 inline" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fill-rule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    Created
                  </span>
                </th>
                <th scope="col" class="px-6 py-3 bg-transparent"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(collection, collectionIdx) in collections"
                :key="collection.id"
                :class="collectionIdx % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'"
              >
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ collection.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-right">
                  {{ collection.num_sources }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-center">
                  <input
                    @change="updateCollection(collection.id)"
                    type="checkbox"
                    v-model="collection.public"
                    class="form-checkbox"
                  />
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ collection.created_at | createdAt }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm">
                  <span
                    @click="editCollection(collection.id)"
                    class="text-sm text-blue-500 hover:text-blue-400 cursor-pointer"
                    >Edit</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import tableMixin from 'mixins/table';

export default {
  mixins: [tableMixin],

  mounted() {
    this.getCollections();
  },
  data() {
    return {
      collections: [],
      activeCollection: { name: '', public: true }
    };
  },

  methods: {
    async getCollections() {
      let response = await axios.get('/collections');
      this.collections = response.data.collections;
    },
    resetCollection() {
      this.activeCollection = {
        name: '',
        public: true
      };
    },
    editCollection(id) {
      this.activeCollection = JSON.parse(JSON.stringify(this.collections.find(x => x.id == id)));
    },
    async createCollection() {
      let response = await axios.post('/collections', this.activeCollection);
      this.collections.push(response.data.collection);
      this.resetCollection();
    },
    async deleteCollection(id) {
      await this.$dialog.confirm('WAIT! Deleting this will delete all of the sources in it too. Continue?');
      await axios.delete('/collections/' + id);
      this.collections.splice(
        this.collections.findIndex(v => v.id === id),
        1
      );
      this.resetCollection();
    },
    async updateCollection(id) {
      let response = await axios.patch('/collections/' + id, this.activeCollection);
      const index = this.collections.findIndex(x => x.id == this.activeCollection.id);
      this.collections[index] = response.data.collection;
      this.resetCollection();
    }
  },
  filters: {
    createdAt(value) {
      return moment(value).format('Y-MM-DD');
    }
  }
};
</script>
