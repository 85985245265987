<template>
  <div>
    <twitterqueries-budget @budgetChange="updateBudgetStatus" @under-budget="overBudget = false" :scheduler-value="schedulerValue" :scheduler-interval="schedulerInterval" :edit-scheduler-value="(twitterQuery || {}).scheduler_value" :edit-scheduler-interval="(twitterQuery || {}).scheduler_interval"></twitterqueries-budget>
    <div v-if="!validLength" class="bg-red-900 text-red-100 px-5 py-2 text-center rounded font-semibold mb-8">
      Uh oh, you selected too many Twitter accounts. (Query Length: {{ queryLength }}/512 chars)
    </div>
    <div class="flex items-start space-x-4">
      <div>
        <span class="font-semibold">Twitter Query</span>
        <input type="text" v-model="name" class="mt-2 form-input block w-64 sm:text-sm sm:leading-5" placeholder="Enter name">
      </div>
      <div>
        <p class="font-semibold">Scrape Every:</p>
        <div class="flex items-center space-x-2 mt-2">
          <input v-model="schedulerValue" type="number" step="1" min="1" placeholder="1" class="w-32">
          <select v-model="schedulerInterval" type="select" class="bg-gray-800 p-2 rounded border-none  font-semibold w-32 form-select">
            <option v-for="interval in schedulerIntervals" :value="interval">{{ interval }}</option>
          </select>
        </div>
      </div>
      <primaryButton @click="editingQuery ? updateTwitterQuery() : createTwitterQuery()" class="self-end w-64" :text="editingQuery ? 'Save' : 'Create Twitter Query'" :disabled="!validLength"></primaryButton>
    </div>
    <div class="mt-4">
      <p class="font-semibold mb-2">Select Twitter Accounts:</p>
      <div v-for="source in sourcesToChooseFrom.slice().sort((a,b)=>a.name > b.name ? 1 : -1)" :key="source.id">
        <label>
          <input v-model="selectedSources" type="checkbox" class="form-checkbox" :value="source.id">
          <span class="ml-1 text-sm">{{ source.name }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ["twitterQuery", "availableSources"],
  data () {
    return {
      name: "",
      maxSources: true,
      selectedSources: [],
      schedulerValue: 1,
      schedulerInterval: "minutes",
      schedulerIntervals: ["seconds", "minutes", "hours"],
      twitterQueries: [],
      enableSourceSelection: false,
      editingQuery: "",
      validLength: true,
      queryLength: "",
      overBudget: false
    }
  },
  computed: {
    sourcesToChooseFrom() {
      if (this.twitterQuery) {
        return this.availableSources.concat(this.twitterQuery.sources)
      }
      return this.availableSources
    }
  },
  mounted () {
    if (this.twitterQuery) {
      this.toggleEditTwitter(this.twitterQuery)
    }
  },
  watch: {
    selectedSources() {
      this.validateTwitterQuery()
    },
  },
  methods: {
    toggleEditTwitter(query) {
      this.editingQuery = query.id
      this.name = query.name
      this.selectedSources = query.sources.map(s=> s.id)
      this.schedulerValue = query.scheduler_value
      this.schedulerInterval = query.scheduler_interval
    },
    clearEditTwitter() {
      this.editingQuery = ''
      this.name = ''
      this.selectedSources = []
    },
    createTwitterQuery() {
      if (!this.validLength) {
        alert("Please remove at least one Twitter so the query length is ok.")
        return
      }
      if (this.overBudget) {
        alert("This would send us over Twitter budget. Please adjust the scheduled speed.")
        return
      }
      axios
      .post("/twitter_queries", { name: this.name, source_ids: this.selectedSources, scheduler_value: this.schedulerValue, scheduler_interval: this.schedulerInterval })
      .then(response => window.location.href= '/twitter_queries').catch(error => console.log(error));
    },
    updateTwitterQuery() {
      axios
      .patch("/twitter_queries/"+this.editingQuery , { name: this.name, source_ids: this.selectedSources, scheduler_value: this.schedulerValue, scheduler_interval: this.schedulerInterval })
      .then(response => window.location.href= '/twitter_queries').catch(error => console.log(error));
    },
    validateTwitterQuery() {
      axios.post("/twitter_query_validations", { source_ids: this.selectedSources }).then(response => {
        this.validLength = response.data.valid_length
        this.queryLength = response.data.query_length
      })
    },
    updateBudgetStatus(overBudget) {
      this.overBudget = overBudget
    }
  }
}
</script>
