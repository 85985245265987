<template>
  <div class="max-w-lg mt-10 mx-auto">
    <span class="font-bold text-lg">API Tokens</span>
    <div v-for="token in apiTokens" class="mt-4 flex justify-between">
      <span>{{ token.token }}</span>
      <span @click="deleteToken(token)" class="cursor-pointer text-red-600 text-sm">Delete</span>
    </div>
    <div class="mt-4">
      <primary-button text="+ New token" @click="createNewToken()"></primary-button>
    </div>
  </div>  
</template>

<script>
import axios from 'axios'
import primaryButton from '../primaryButton.vue'
export default {
    components: { primaryButton },
  props: ['apiTokens'],
  methods: {
    createNewToken() {
      axios.post("/api_tokens").then(response => window.location.reload() ).catch(error => console.log(error))
    },
    deleteToken(token) {
      this.$dialog.confirm("This will break your existing apps using this token! Are you sure?").then(() => {
        axios.delete("/api_tokens/"+token.id).then(response => window.location.reload()).catch(error => console.log(error))
      });
    }
  }
}
</script>