  <template>
  <div class="px-10 my-10 flex flex-col space-x-10 max-w-3xl mx-auto">
    <div class="flex items-center space-x-4 justify-center mb-8">
      <span @click="mode = 'tags'" class="cursor-pointer select-none font-semibold text-sm border-b px-4 pb-2" :class="mode == 'tags' ? 'text-white border-white' : 'text-gray-400 border-gray-900'">Tags</span>
      <span @click="mode = 'regexgroups'" class="cursor-pointer select-none font-semibold text-sm border-b px-4 pb-2" :class="mode == 'regexgroups' ? 'text-white border-white' : 'text-gray-400 border-gray-900'">Regex Groups</span>
    </div>
    <div v-show="mode == 'regexgroups'">
      <tags-regex-group-form v-for="regexGroup in modelGroups" :regex-group="regexGroup" class="mt-4" :key="regexGroup.name" @deleteRegexGroup="onDeleteRegexGroup"></tags-regex-group-form>
      <tags-regex-group-form class="border-t-2 border-gray-700 mt-3 py-3"  @createRegexGroup="onCreateRegexGroup"></tags-regex-group-form>
    </div>
    <div v-show="mode == 'tags'">
      <div class="flex justify-center mb-10">
        <div class="flex items-center space-x-10">
          <div>
            <span class="text-sm font-semibold">{{editTagSubGroup ? 'Edit' : 'Create'}} Tag Subgroup</span>
            <div class="mt-1 flex space-x-3">
              <select v-model="tagGroupId" class="form-select py-1 text-sm w-32 bg-gray-800 border-0">
                <option v-for="group in tagGroups" :value="group.id">{{ group.name }}</option>
              </select>
              <input v-model="tagSubGroupName" type="text" class="form-input text-sm py-1 w-32" placeholder="Coins">
              <primaryButton :text="editTagSubGroup ? 'Save' : 'Add'" @click="editTagSubGroup ? updateSubGroup() : createSubGroup()"></primaryButton>
            </div>
          </div>
          <div>
            <span class="text-sm font-semibold"> {{ editTagGroup ?  "Edit" : "Create" }} Tag Group</span>
            <div class="mt-1 flex space-x-3">
              <input v-model="tagGroupName" type="text" class="form-input text-sm py-1 w-32" placeholder="Topics">
              <primaryButton :text="editTagGroup ? 'Save' : 'Add'" @click="editTagGroup ? updateTagGroup() :  createGroup()"></primaryButton>
            </div>
          </div>
        </div>
      </div>
      <div v-for="group in tagGroups" :key="group.id" class="mb-8">
        <div @click="toggleShowGroup(group)" class="border-b-2 border-gray-700 py-2 cursor-pointer select-none flex">
          <h2 class="font-bold" >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-gray-600 w-5 h-5 inline mb-px">
              <path v-if="expandedGroups.includes(group.id)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            {{ group.name }}

            <svg @click="editGroup(group, $event)" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 w-5 h-5 inline mb-px" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
              </svg>
          </h2>
          <span class="ml-auto self-end uppercase tracking-wider text-gray-600 font-semibold text-xs">Group</span>
        </div>
        <div v-show="expandedGroups.includes(group.id)" class="ml-8" v-for="subgroup in group.tag_subgroups.slice().sort((a,b) => a.name > b.name ? 1 : -1)" :key="subgroup.id">
          <div @click="toggleShowSubGroup(subgroup)" class="border-b-2 border-gray-700 py-2 mt-2 cursor-pointer select-none flex">
            <h2 class="font-semibold">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-gray-600 w-5 h-5 inline mb-px">
                <path v-if="expandedSubGroups.includes(subgroup.id)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {{ subgroup.name }}
              <svg @click="editSubGroup(subgroup, $event)" xmlns="http://www.w3.org/2000/svg" class="text-gray-600 w-5 h-5 inline mb-px" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
              </svg>
            </h2>
            <span class="ml-auto self-end uppercase tracking-wider text-gray-600 font-semibold text-xs">Subgroup</span>
          </div>
          <div v-show="expandedSubGroups.includes(subgroup.id)" class="ml-8">
            <div class="border-b-2 border-gray-700 py-2 mt-2 flex">
              <h2 class="font-semibold">
                Add New Tag
              </h2>
            </div>
            <tags-form class="mt-2" :active-sub-group="subgroup" :tag-sub-groups="tagSubGroupsIdsAndNames" @createdTag="onCreatedTag"></tags-form>
          </div>
          <div v-show="expandedSubGroups.includes(subgroup.id)" class="ml-8" v-for="tag in subgroup.tags.slice().sort((a,b) => a.name > b.name ? 1 : -1)" :key="tag.id">
            <div @click="toggleShowTag(tag)" class="border-b-2 border-gray-700 py-2 mt-2 cursor-pointer select-none flex">
              <h2 class="font-semibold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-gray-600 w-5 h-5 inline mb-px">
                  <path v-if="expandedTags.includes(tag.id)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                {{ tag.name }}
              </h2>
              <span class="ml-auto self-end uppercase tracking-wider text-gray-600 font-semibold text-xs">Tag</span>
            </div>
            <tags-form v-show="expandedTags.includes(tag.id)" class="mt-2" :active-sub-group="subgroup" :tag="tag" :tag-sub-groups="tagSubGroupsIdsAndNames" :key="tag.id" @deleteTag="onDeleteTag"></tags-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  mounted() {
    this.getTagData();
  },
  data () {
    return {
      mode: "tags",
      tagGroups: [],
      modelGroups: [],
      tagSubGroupName: "",
      tagGroupName: "",
      editTagSubGroup: "",
      editTagGroup:"",
      tagSubGroups: [],
      tagGroupId: "",
      tagSubGroupsIdsAndNames: [],
      expandedGroups: JSON.parse(window.localStorage.getItem("expandedGroups")) || [],
      expandedSubGroups: JSON.parse(window.localStorage.getItem("expandedSubGroups")) || [],
      expandedTags: JSON.parse(window.localStorage.getItem("expandedTags")) || []
    }
  },
  methods: {
    getTagData() {
      axios.get("/tags").then(response => {
        this.tagGroups = response.data.tag_groups;
        this.modelGroups = response.data.model_groups;
        this.tagSubGroups = this.tagGroups.length > 0 ? this.tagGroups.map(g=>g.tag_subgroups).flat() : [];
        this.tagGroupId = this.tagGroups.length > 0 ? this.tagGroups[0].id : "";
        this.tagSubGroupsIdsAndNames = this.tagSubGroups.map(s => {return {id: s.id, name: s.name, groupId: s.tag_group_id}})
      })
    },
    createGroup() {
      axios
      .post("/tag_groups", { name: this.tagGroupName }).then(response => {
        this.tagGroups.push(response.data.tag_group);
        this.tagGroupName = "";
      })
    },
    editGroup(group , $event){
      this.editTagGroup = group.id
      this.tagGroupName = group.name
      $event.stopPropagation();
    },
    editSubGroup(group , $event){
      this.editTagSubGroup = group.id
      this.tagSubGroupName = group.name
      this.tagGroupId = group.tag_group_id
      $event.stopPropagation();
    },
    updateTagGroup(){
     axios
      .patch("/tag_groups/"+ this.editTagGroup, { name: this.tagGroupName }).then(response => {
        const index = this.tagGroups.findIndex(x => x.id == this.editTagGroup);
        this.tagGroups[index] = response.data.tag_group;
        this.tagGroupName = "";
      })
    },
    createSubGroup() {
      axios
      .post("/tag_subgroups", { tag_group_id: this.tagGroupId, name: this.tagSubGroupName }).then(response => {
        let group = this.tagGroups.find(g => g.id == this.tagGroupId)
        group.tag_subgroups.push(response.data.tag_subgroup);
        this.tagSubGroupName = "";
      })
    },
    updateSubGroup() {
      axios
      .patch("/tag_subgroups/"+this.editTagSubGroup, { tag_group_id: this.tagGroupId, name: this.tagSubGroupName }).then(response => {
        const group = this.tagGroups.find(x => x.id == this.tagGroupId);
        const subGroupId = group.tag_subgroups.findIndex(x => x.id == this.editTagSubGroup);
        group.tag_subgroups[subGroupId] = response.data.tag_subgroup;
        this.tagSubGroupName = "";
      })
    },
    toggleShowGroup(group) {
      if (this.expandedGroups.includes(group.id)) {
        this.expandedGroups = this.expandedGroups.filter(g => g != group.id)
      }
      else {
        this.expandedGroups.push(group.id)
      }
      window.localStorage.setItem("expandedGroups", JSON.stringify(this.expandedGroups))
    },
    toggleShowSubGroup(subgroup) {
      if (this.expandedSubGroups.includes(subgroup.id)) {
        this.expandedSubGroups = this.expandedSubGroups.filter(g => g != subgroup.id)
      }
      else {
        this.expandedSubGroups.push(subgroup.id)
      }
      window.localStorage.setItem("expandedSubGroups", JSON.stringify(this.expandedSubGroups))
    },
    toggleShowTag(tag) {
      if (this.expandedTags.includes(tag.id)) {
        this.expandedTags = this.expandedTags.filter(g => g != tag.id)
      }
      else {
        this.expandedTags.push(tag.id)
      }
      window.localStorage.setItem("expandedTags", JSON.stringify(this.expandedTags))
    },
    onDeleteTag(val) {
      const group = this.tagGroups.find(x => x.id == val.tagGroupId);
      const subGroup = group.tag_subgroups.find(x => x.id == val.tagSubgroupId);
      subGroup.tags = subGroup.tags.filter(x => x.id !== val.tagId);
    },
    onCreatedTag(val) {
      const group = this.tagGroups.find(x => x.id == val.tagGroupId);
      const subGroup = group.tag_subgroups.find(x => x.id == val.tag.tag_subgroup_id);
      subGroup.tags.push(val.tag)
    },
    onDeleteRegexGroup(val) {
      this.modelGroups = this.modelGroups.filter(x => x.id !== val);
    },
    onCreateRegexGroup(val) {
      this.modelGroups.push(val);
    }
  }
}
</script>
