import axios from 'axios';

export default function setup(app) {
  axios.interceptors.response.use(
    response => {
      if ([200, 201, 202].includes(response.status) && response.data.message) {
        app.$notify(
          {
            group: 'app',
            type: 'flash',
            text: {
              type: 'notice',
              message: response.data.message
            }
          },
          2000
        );
      }
      return response;
    },
    error => {
      const statusCode = error.response ? error.response.status : null;
      if (error.response.data.errors) {
        error.response.data.errors.forEach(err => {
          app.$notify(
            {
              group: 'app',
              type: 'flash',
              text: {
                type: 'error',
                message: `${err.field} ${err.code}`
              }
            },
            2000
          );
        });
      } else if (error.response.data.message) {
        app.$notify(
          {
            group: 'app',
            type: 'flash',
            text: {
              type: 'error',
              message: error.response.data.message
            }
          },
          2000
        );
      } else if (statusCode === 404) {
        app.$notify(
          {
            group: 'app',
            type: 'flash',
            text: {
              type: 'error',
              message: 'The requested resource does not exist or has been deleted'
            }
          },
          2000
        );
      } else if (statusCode === 401) {
        app.$notify(
          {
            group: 'app',
            type: 'flash',
            text: {
              type: 'error',
              message: 'Unauthorized'
            }
          },
          2000
        );
      } else {
        console.error(error);
      }
      return Promise.reject(error);
    }
  );
}
