<template>
  <div>
    <div class="flex justify-center">
      <date-picker
        v-model="selectedDate"
        range
        value-type="format"
        input-class="text-white text-sm w-full"
        placeholder="Start Date ~ End Date"
      ></date-picker>
      <button class="bg-green-600 hover:bg-green-500 p-2 rounded font-semibold text-sm text-center cursor-pointer ml-5" :class="disabled ? 'bg-opacity-50 cursor-not-allowed' : 'bg-opacity-100'" @click="backfillNews()">Submit</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  mounted() {
    this.subscribeToUpdates()
  },
  components: {
    DatePicker
  },
  data () {
    return {
      disabled: false,
      selectedDate: null
    }
  },
  methods: {
    backfillNews() {
      if (!this.disabled) {
        this.disabled = true
        axios.post('/backfill/news', { dates: this.selectedDate}).then(() => {
          this.disabled = false
        })
      }
    },
    subscribeToUpdates() {
      const self = this;
      self.$root.store.cable.subscriptions.create(
        { channel: 'UserChannel', user_id: this.currentUser },
        {
          connected() {},
          received(data) {
            if (data.message) {
              self.$notify(
                {
                  group: 'app',
                  type: 'flash',
                  text: {
                    type: 'notice',
                    message: data.message
                  }
                },
                5000
              );
            }
          }
        }
      );
    }
  }
}
</script>
