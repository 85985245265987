// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start();
// require("@rails/activestorage").start();
import { createConsumer } from '@rails/actioncable';
import Notifications from 'vt-notifications';

require('css/application.scss');
require.context('../images', true);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Vue from 'vue/dist/vue.esm';
import './filters.js';
import PortalVue from 'portal-vue';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import ConfirmationModal from 'components/modals/ConfirmationModal.vue';
import Button from 'components/base/Button.vue';

// include the default style
Vue.use(VuejsDialog, {
  view: 'ConfirmationModal'
});
Vue.dialog.registerComponent('ConfirmationModal', ConfirmationModal);
Vue.component('Button', Button);
Vue.use(Notifications);
Vue.use(PortalVue);
import responseHandler from 'helpers/response_handler';

const req = require.context('../components', true, /\.(js|vue)$/i);
req.keys().map(key => {
  const name = key.replace('./', '').replace('/', '-').replace('.vue', '');
  return Vue.component(name, req(key).default);
});

import axios from 'axios';
document.addEventListener('DOMContentLoaded', () => {
  axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute('content');
  axios.defaults.headers.common['Accept'] = 'application/json';

  const app = new Vue({
    el: '#app',
    data() {
      const host = window.location.hostname;
      const protocol = host.includes('localhost') ? 'ws://' : 'wss://';
      const port = host.includes('localhost') ? ':3000' : '';
      return {
        store: {
          currentTeam: null,
          cable: createConsumer(`${protocol + host + port}/cable`)
        }
      };
    },
    mounted() {
      axios
        .get('/current_team')
        .then(response => (this.store.currentTeam = response.data))
        .catch(error => console.log(error));
    }
  });
  responseHandler(app);
});
