<template>
<div class="p-10">
  <div class="">
    <span class="block py-2 font-semibold">Health Check Rules</span>
    <span class="block">Notify when a source fails
      <input type="number" class="form-input inline w-20" v-model.number="parsing_failed_count" @keyup.enter="updateRuleValue('parsing_failed_count', parsing_failed_count)">
      times.
    </span>
    <span class="block">Notify when a source has zero links
      <input type="number" class="form-input inline w-20" v-model.number="zero_links_count" @keyup.enter="updateRuleValue('zero_links_count', zero_links_count)">
      times while parsing.
    </span>
  </div>
  <div class="flex justify-between ">
    <div class="pt-8 pb-2 h-90">
      <div class="flex text-sm space-x-4">
        <input type="text" class="form-input inline w-64" placeholder="Search sources" v-model="searchText">
        <button v-if="$root.store.userRole == 'admin'" class="text-btn text-blue-400" @click="markAllResolved()">Mark All Resolved</button>
        <button v-if="$root.store.userRole == 'admin'" class="text-btn text-red-400" @click="deleteAllNotifications()">Delete All Resolved</button>
      </div>
    </div>
  </div>
  <div class="mt-10 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="shadow overflow-hidden sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-700 font-semibold">
          <thead class="bg-gray-900">
            <tr>
              <th class="px-3 text-left rounded-tl">#</th>
              <th class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap" v-for="(column, id) in columns" :key="id" @click="toggleColumnSort(id)">
                <div class="relative">
                  <div class="flex items-center" :class="[column.align || 'float-right']">
                    <svg v-if="columnDirection == 'desc' && id == sortColumn" class="w-4 h-4 flex-shrink-0 mr-1" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                    <svg v-if="columnDirection == 'asc' && id == sortColumn" class="w-4 h-4 flex-shrink-0 mr-1" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path>
                    </svg>
                    <span>{{ column.name }}</span>
                  </div>
                </div>
              </th>
              <th v-if="$root.store.userRole == 'admin'" scope="col" class="px-6 py-3 bg-transparent">
              </th>
            </tr>
          </thead>
          <template v-if="sortedNotifications.length > 0">
            <tr v-for="(notification, notificationIdx) in sortedNotifications" :key="notification.id" :class="notificationIdx % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'">
              <td class="px-3">{{ notificationIdx + 1 }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-center">{{ notification.name }} (id: {{ notification.source_id }})</td>
              <td class="max-w-sm">
                <span class="line-clamp-3">
                  {{ notification.message }}
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-center">{{ notification.parsing_failed_count }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-center">{{ notification.zero_links_count }}</td>
              <td>
                <span v-if="notification.is_resolved" class="badge badge-green">Resolved</span>
                <span v-else class="px-6 whitespace-nowrap text-sm text-center badge badge-red">Unresolved</span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-center">{{ notification.created_at | relativeTime }}</td>
              <td v-if="$root.store.userRole == 'admin'" class="px-6 py-4 whitespace-nowrap text-sm text-center space-x-4">
                <template v-if="!notification.is_resolved">
                  <button class="text-btn text-btn-blue" @click="markResolved(notification.id, notification.source_id)">Mark Resolved</button>
                </template>
                <button class="text-btn text-btn-red" @click="deleteNotification(notification.id)">Delete</button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7" class="text-center font-bold text-md py-20">No errors at the moment.</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div>
    <pagination-bar :page="page" :per_page="per_page" :query_total="query_total" @pageClick="pageChange" />
  </div>
</div>
</template>

<script>
import tableMixin from '../mixins/table';
import axios from 'axios';
export default {
  mixins: [tableMixin],
  props: ['healthCheckRules'],
  mounted() {
    this.loadNotifications();
  },
  computed: {
    sortedNotifications() {
      return this.sortByAttribute(this.editableNotifications, this.sortColumn, this.columnDirection);
    }
  },
  watch: {
    notificationsLimit() {
      localStorage.setItem('notificationsLimit', this.notificationsLimit);
    }
  },
  data() {
    return {
      page: 1,
      per_page: 15,
      query_total: 0,
      editableNotifications: [],
      parsing_failed_count: this.healthCheckRules.find(x => x.name == 'parsing_failed_count').value,
      zero_links_count: this.healthCheckRules.find(x => x.name == 'zero_links_count').value,
      columns: {
        name: {
          name: 'Source',
          align: 'text-left'
        },
        message: {
          name: 'Message',
          align: 'text-left'
        },
        parsing_failed_count: {
          name: 'No. of Failed Parse',
          align: 'text-left'
        },
        zero_links_count: {
          name: 'No. of Zero Links',
          align: 'text-left'
        },
        is_resolved: {
          name: 'Status',
          align: 'text-left'
        },
        created_at: {
          name: 'Dated',
          align: 'text-left'
        }
      },
      searchText: '',
      notificationsLimit: localStorage.getItem('notificationsLimit') || 50
    };
  },
  methods: {
    loadNotifications() {
      axios
        .get('/', {
          params: {
            page: this.page,
            per_page: this.per_page
          }
        })
        .then(response => {
          this.query_total = response.data.all_data.total_items;
          this.modifyNotifications(response.data.all_data.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateRuleValue(rule, value) {
      axios.post('/health_check_rules', {
        name: rule,
        value: value
      });
    },
    modifyNotifications(notifications) {
      this.editableNotifications = notifications.map(x => {
        return {
          id: x.id,
          source_id: x.source.id,
          name: x.source.name,
          parsing_failed_count: x.source.parsing_failed_count,
          zero_links_count: x.source.zero_links_count,
          created_at: x.created_at,
          is_resolved: x.is_resolved,
          message: x.message
        };
      });
    },
    markResolved(id, source_id) {
      const self = this;
      self.$dialog.confirm('Are you sure?').then(() => {
        axios
          .patch(`/health_check_notifications/${id}`, {
            source: source_id
          })
          .then(response => {
            let notification = self.editableNotifications.find(x => x.id == id);
            notification.is_resolved = true;
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    deleteNotification(id) {
      const self = this;
      self.$dialog.confirm('Are you sure?').then(() => {
        axios
          .delete(`/health_check_notifications/${id}`)
          .then(response => {
            self.editableNotifications = self.editableNotifications.filter(x => x.id != id);
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    markAllResolved() {
      const self = this;
      self.$dialog.confirm('Are you sure?').then(() => {
        axios
          .patch(`/health_check_notifications/0`)
          .then(response => {
            self.editableNotifications.forEach(x => {
              x.is_resolved = true;
            });
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    deleteAllNotifications() {
      const self = this;
      self.$dialog.confirm('Are you sure?').then(() => {
        axios
          .delete(`/health_check_notifications/0`)
          .then(response => {
            self.editableNotifications = self.editableNotifications.filter(x => !x.is_resolved);
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    pageChange(idx, total_pages) {
      if (((idx >= 1) & (idx <= total_pages)) | (total_pages == -1)) {
        this.page = idx;
        this.loadNotifications();
      }
    }
  },
  watch: {
    searchText(val) {
      const self = this;
      if (self.searchText.trim() === '') {
        self.loadNotifications();
      } else {
        self.editableNotifications = self.editableNotifications.filter(x => {
          return x.name.toLowerCase().includes(self.searchText.toLowerCase());
        });
      }
    }
  }
};
</script>
