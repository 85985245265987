<template>
    <div class="flex w-full items-center rounded-md bg-white px-2 py-1">
        <svg v-if="icon" :class="mini? 'mx-0' : 'mx-1'" class="w-4 h-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        <input type="text" v-model="search"  v-on:keyup.enter="searchedText"  ref="search" class="w-full bg- focus:outline-none border-none focus:shadow-outline-none focus:ring-0 font-bold" :class="mini? 'px-2 py-1 text-xs ': 'p-2 text-sm'" :placeholder="placeholder">
    </div>
</template>

<script>
export default {
  props: {
    mini: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Search'
    }
  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    searchedText() {
      console.log("i am calling")
      this.$emit('searched', this.search.toLowerCase())
    },
   
  }
}
</script>
