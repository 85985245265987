<template>
  <form @submit.prevent="regexGroup ? updateRegexGroup() : createRegexGroup()">
    <div class="flex items-center">
      <input required type="text" class="text-sm form-input" v-model="name" placeholder="Regex group name">
      <svg v-if="regexGroup && $root.store.userRole == 'admin'" @click="deleteRegexGroup" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="flex-shrink-0 w-4 h-4 ml-4 text-red-600 cursor-pointer">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
      </svg>
    </div>
    <textarea required rows="8" class="w-full mt-2 text-sm bg-gray-800 border-0 form-textarea" v-model="rule" placeholder='r"price analysis", r"analyst predicts"'></textarea>
    <input type="text" class="w-full text-sm form-input" v-model="testHeadline" placeholder="Test a headline">
    <input type="text" class="w-full mt-2 text-sm form-input" v-model="testURL" placeholder="Test a URL">
    <div class="flex items-center mt-2">
      <div v-if="testResult.length > 0">
        <span class="text-sm text-gray-600">Score:</span> <span class="text-lg font-bold">{{ testResult[0].score }}</span>
      </div>
      <span @click="testModel" class="ml-auto text-sm font-semibold text-blue-400 cursor-pointer">Test model</span>
      <primary-button class="ml-4" :text="regexGroup ? 'Save' : 'Add'"></primary-button>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import primaryButton from '../primaryButton.vue'
export default {
    components: { primaryButton },
  props: ['regexGroup'],
  data () {
    return {
      name: "",
      rule: "",
      testHeadline: "",
      testURL: "",
      testResult: []
    }
  },
  mounted () {
    if (this.regexGroup) {
      this.name = this.regexGroup.name
      this.rule = this.regexGroup.rule
    }
  },
  methods: {
    createRegexGroup() {
      axios
      .post("/model_groups", { name: this.name, rule: this.rule.trim() }).then(response => {
        this.$emit('createRegexGroup', response.data.model_group);
        this.resetModelGroup();
      })
    },
    resetModelGroup () {
      this.name = "";
      this.rule = "";
      this.testHeadline = "";
      this.testURL = "";
      this.testResult = [];
    },
    updateRegexGroup() {
      axios
      .patch("/model_groups/" + this.regexGroup.id, { name: this.name, rule: this.rule.trim() })
    },
    deleteRegexGroup() {
      this.$dialog.confirm(`Are you sure you want to delete ${this.regexGroup.name}?`).then(() => {
        axios
        .delete("/model_groups/" + this.regexGroup.id)
        .then(response => {
          this.$emit('deleteRegexGroup', this.regexGroup.id)
        })
        .catch(error => console.log(error))
      });
    },
    testModel() {
      // Prepending the rule with 1: in order for the test to generate the score
      axios
      .post("/tag-model-test", { url: this.testURL, headline: this.testHeadline, tag_model: "1: " + this.rule.trim() })
      .then(response => {
        this.testResult = response.data.data
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

}
</script>
