<template>
  <div class="custom-view-wrapper" id="custom-view-template">
    <template>
      <BaseModal :show="show" max-width="lg" @close="$emit('close')">
        <template #header>
          <div class="flex items-center font-semibold text-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mr-2 text-red-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span>Action Required</span>
          </div>
        </template>
        <template #body>
          <template>
            <div class="flex items-center text-sm" v-html="messageBody"></div>
          </template>
        </template>
        <template #footer>
          <div class="ml-auto flex space-x-4 mt-5">
            <Button type="secondary" @click="handleDismiss()">Cancel</Button>
            <Button type="danger" @click="handleConfirm()">Proceed</Button>
          </div>
        </template>
      </BaseModal>
    </template>
  </div>
</template>
<script>
import BaseModal from 'components/modals/BaseModal.vue';
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // Include mixin
import Button from 'components/base/Button.vue';

export default {
  mixins: [DialogMixin],
  name: 'ConfirmationModal',
  components: {
    BaseModal,
    Button
  },
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  watch: {},
  computed: {},
  methods: {
    handleConfirm() {
      this.proceed(); // included in DialogMixin
    },
    handleDismiss() {
      this.cancel(); // included in DialogMixin
    }
  }
};
</script>
