<template>
  <div class="ml-auto flex flex-row p-2 items-center rounded space-x-4">
    <div
      @click="$emit('pageClick', page - 1, totalPages)"
      class="px-2 py-1 rounded"
      :class="page > 1 ? ' cursor-pointer text-white bg-blue-600' : 'cursor-default text-gray-500 bg-gray-700'"
    >
      Previous
    </div>
    <div v-if="prev_dotted" class="py-1 px-2 cursor-pointer" @click="$emit('pageClick', 1, totalPages)">1</div>
    <div v-if="prev_dotted" class="py-1 px-2">...</div>
    <div
      v-for="idx in pageList"
      :key="idx"
      class="py-1 px-2 cursor-pointer"
      @click="$emit('pageClick', idx, totalPages)"
      :class="page == idx ? ' text-blue-600 border-2 border-blue-600 rounded' : 'text-white'"
    >
      {{ idx }}
    </div>
    <div v-if="forw_dotted" class="py-1 px-2">...</div>
    <div v-if="forw_dotted" class="py-1 px-2 cursor-pointer" @click="$emit('pageClick', totalPages, totalPages)">
      {{ totalPages }}
    </div>
    <div
      @click="$emit('pageClick', page + 1, totalPages)"
      :class="page < totalPages ? ' cursor-pointer text-white bg-blue-600' : 'cursor-default text-gray-500 bg-gray-700'"
      class="ml-2 px-2 py-1 rounded"
    >
      Next
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationBar',
  props: ['page', 'query_total', 'per_page'],
  data() {
    return {
      prev_dotted: false,
      forw_dotted: false
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.query_total / this.per_page);
    },
    pageList() {
      let pages = [];
      let list_start = 1;
      let list_end = this.totalPages;

      this.prev_dotted = false;
      this.forw_dotted = false;

      if (this.totalPages <= 10) return this.totalPages;
      else if (this.page > 5) {
        this.prev_dotted = true;
        list_start = this.page - 2;
      }
      if (this.page < this.totalPages - 5) {
        this.forw_dotted = true;
        list_end = this.page + 3;
      }

      for (let i = list_start; i < list_end; i++) pages.push(i);
      return pages;
    }
  }
};
</script>

<style></style>
