<template>
  <div class="p-10">
    <span class="font-semibold">Create a Team</span>
    <form @submit.prevent="createTeam()" class="flex items-center space-x-5 max-w-xl">
      <div class="w-full">
        <div class="mt-1 relative rounded-md shadow-sm">
          <input type="text" required id="name" v-model="teamName" class="form-input block w-full sm:text-sm sm:leading-5" placeholder="The TIE">
        </div>
      </div>
      <primaryButton text="Create"></primaryButton>
    </form>
    <div class="mt-10">
      <div v-for="team in teams" class="flex space-x-4">
        <span class="font-bold">{{ team.name }}</span>
        <span class="text-gray-600" v-if="$root.store.currentTeam && $root.store.currentTeam.id == team.id">Current Team</span>
        <span @click="setActiveTeam(team.id)" class="text-blue-500 cursor-pointer" v-else>Switch to this team</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['teams'],
  data () {
    return {
      teamName: ""
    }
  },
  methods: {
    createTeam() {
      axios
      .post('/teams', { name: this.teamName })
    },
    setActiveTeam(id) {
      axios.patch(`/current_team/${id}`)
      .then(response => {
        this.$root.store.currentTeam = response.data
      })
      .catch(error => console.log(error.message))
    }
  }
}
</script>
