import Vue from 'vue/dist/vue.esm';
import moment from 'moment';

Vue.filter('createdAt', function (value) {
  return moment(value).format('Y-MM-DD H:mm');
});

Vue.filter('relativeTime', function (value) {
  if (value) {
    return moment(value).fromNow();
  }
  return '';
});
