<template>
  <div>
    <div class="flex">
      <a href="/sources/new?twitter=true" class="bg-green-600 hover:bg-green-500 p-3 rounded font-semibold text-sm">+ Add Twitter Account</a>
      <a href="/twitter_queries/new" class="ml-8 bg-blue-600 hover:bg-blue-500 p-3 rounded font-semibold text-sm">+ Add Twitter Query</a>
      <input v-model="search" type="text" placeholder="Search Twitter Queries (query name or Twitter handle)" class="ml-auto w-1/2">
    </div>
    <div class="mt-10 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <ul class="flex">
          <li v-for="m in modes" @click="mode = m" class="border-b pb-3 px-5 cursor-pointer font-semibold" :class="m == mode ? 'text-blue-400 border-blue-400' : 'text-gray-600 border-gray-600'">
            {{ m }}
          </li>
        </ul>
        <div class="shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-700 font-semibold">
            <thead class="bg-gray-900">
              <tr v-if="mode == 'Queries'">
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Twitter Accounts
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Schedule
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Latest Tweet Time
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Budget Used
                </th>
                <th scope="col" class="px-6 py-3 bg-transparent">
                </th>
              </tr>
              <tr v-if="mode == 'Accounts'">
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Twitter ID
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Twitter Query
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Collections
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap">
                  Latest Tweet Time
                </th>
                <th scope="col" class="px-6 py-3 bg-transparent">
                </th>
              </tr>
            </thead>
            <tbody v-if="mode == 'Queries'">
              <tr v-for="(query, ix) in filteredQueries" :key="query.id" :class="ix % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'">
                <td class="px-6 py-2 text-xs">
                  {{ query.name }}
                </td>
                <td class="px-6 py-2 text-xs text-left">
                  <template v-for="(source, index) in query.sources">
                    <a :href="`/sources/${source.id}/edit`">{{ source.name }}
                      <span v-if="index + 1 < query.sources.length">, </span>
                    </a>
                  </template>
                </td>
                <td class="px-6 py-3 whitespace-no-wrap text-xs">
                  <span>{{ query.scheduler_value }} {{ query.scheduler_interval }}</span>
                </td>
                <td class="px-6 py-3 whitespace-no-wrap text-xs">
                  <span>{{ query.last_scrape | relativeTime(query.last_scrape) }}</span>
                </td>
                <td class="px-6 py-3 whitespace-no-wrap text-xs text-left">
                  <span>{{ query.budget_used.toLocaleString('en', { maximumFractionDigits: 0 }) }} <span class="text-gray-500">requests</span></span>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-right text-sm">
                  <span v-if="query.is_enable_job && $root.store.userRole == 'admin'" @click="disableQuery(query.id)" class="text-sm text-red-500 hover:text-red-400 cursor-pointer">- Disable</span>
                  <span v-else-if="$root.store.userRole == 'admin'" @click="enableQuery(query.id)" class="text-sm text-green-500 hover:text-green-400 cursor-pointer">+ Enable</span>
                  <a :href="`/twitter_queries/${query.id}/edit`" class="ml-4 text-sm text-blue-500 hover:text-blue-400 cursor-pointer">Edit</a>
                  <span v-if="$root.store.userRole == 'admin'" class="ml-4 text-sm text-red-500 hover:text-red-400 cursor-pointer" @click="deleteTwitterQuery(query.id)">Delete</span>
                </td>
              </tr>
            </tbody>
            <tbody v-if="mode == 'Accounts'">
              <tr v-for="(source, ix) in filteredSources" :key="source.id" :class="ix % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'">
                <td class="px-6 py-3 text-xs">
                  <a :href="`/sources/${source.id}/edit`">{{ source.name }}</a>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  <span>{{ source.twitter_id }}</span>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  <a v-for="(q, index) in source.twitter_queries" :href="`/twitter_queries/${q.id}/edit`">
                    {{ q.name }}
                    <span v-if="index + 1 < source.twitter_queries.length">, </span>
                  </a>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  <span>{{ source.collections.map(c => c.name).join(', ') }}</span>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  <span>{{ source.last_scrape | relativeTime(source.last_scrape) }}</span>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-right text-sm">
                  <a :href="`/sources/${source.id}/edit`" class="ml-4 text-sm text-blue-500 hover:text-blue-400 cursor-pointer">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
  mounted() {
    this.getQueries();
  },
  data() {
    return {
      queries: [],
      sources: [],
      search: '',
      mode: window.location.pathname.includes('twitter_queries/accounts') ? 'Accounts' : 'Queries',
      modes: ['Queries', 'Accounts']
    }
  },
  methods: {
    getQueries() {
      axios.get('/twitter_queries.json').then(response => {
        this.queries = response.data.twitter_queries
        this.sources = response.data.twitter_accounts
      }).catch(error => console.log(error))
    },
    enableQuery(query_id) {
      axios
      .post('/twitter_query_jobs', { id: query_id })
      .then(() => this.getQueries())
      .catch(error => console.log(error))
    },
    disableQuery(query_id) {
      axios
      .delete(`/twitter_query_jobs/${query_id}`)
      .then(() => this.getQueries())
      .catch(error => console.log(error))
    },
    async deleteTwitterQuery(query_id) {
      await this.$dialog.confirm('Are you sure?')
      await axios.delete(`/twitter_queries/${query_id}`);
      this.queries = this.queries.filter(x => x.id !== query_id);
    }
  },
  computed: {
    filteredQueries() {
      return this.queries.filter(q => {
        return this.search == "" ||
        q.name.toLowerCase().includes(this.search.toLowerCase()) ||
        q.sources.map(s => s.name.toLowerCase()).join(", ").includes(this.search.toLowerCase())
      }).sort((a,b) => a.budget_used > b.budget_used ? -1 : 1)
    },
    filteredSources() {
      return this.sources.filter(s => {
        return this.search == "" ||
        s.name.toLowerCase().includes(this.search.toLowerCase()) ||
        s.collections.map(c => c.name.toLowerCase()).join(", ").includes(this.search.toLowerCase())
      }).sort((a,b) => a.budget_used > b.budget_used ? -1 : 1)
    }
  },
  filters: {
    relativeTime(t) {
      if (t) {
        return moment(t).fromNow()
      }
      return null
    }
  }
}
</script>
