<template>
  <BaseModal :show="show" max-width="lg" @close="close">
    <template #body>
      <textarea
        required
        v-model="action.params.model"
        class="mt-2 form-textarea text-sm w-full bg-gray-800 border-0"
        rows="5"
        placeholder='10: r"this is a test model", hello'
      ></textarea>
      <input
        type="text"
        class="form-input text-sm w-full mt-2"
        v-model="testHeadline"
        placeholder="Test a headline (r tag)"
      />
      <input type="text" class="form-input text-sm w-full mt-2" v-model="testURL" placeholder="Test a URL (u tag)" />
      <input
        type="text"
        class="form-input text-sm w-full mt-2"
        v-model="testSourceUrl"
        placeholder="Test a Source URL (p tag)"
      />
      <input
        type="text"
        class="form-input text-sm w-full mt-2"
        v-model="testSourceName"
        placeholder="Test a Source Name (t tag)"
      />
      <input
        type="text"
        class="form-input text-sm w-full mt-2"
        v-model="testCollectionName"
        placeholder="Test a Collection Names (c tag)"
      />
      <div class="mt-3 flex items-center text-sm">
        <div v-if="tagTestResult.length > 0">
          <span class="text-gray-600 text-sm">Score:</span>
          <span class="text-lg font-bold">{{ tagTestResult[0].score }}</span>
        </div>
        <span @click="testTagModel()" class="ml-auto text-blue-400 font-semibold cursor-pointer px-3"
          >Test tag model</span
        >
      </div>
    </template>
  </BaseModal>
</template>
<script>
import axios from 'axios';
import BaseModal from './BaseModal';
export default {
  name: 'TestTagModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    action: {
      type: Object
    }
  },
  components: {
    BaseModal
  },
  data() {
    return {
      testHeadline: '',
      testURL: '',
      testSourceUrl: '',
      testSourceName: '',
      testCollectionName: '',
      tagTestResult: {}
    };
  },
  methods: {
    close() {
      this.testHeadline = '';
      this.testURL = '';
      this.testSourceName = '';
      this.testCollectionName = '';
      this.testSourceUrl = '';
      this.tagTestResult = {};
      this.$emit('close');
    },
    testTagModel() {
      axios
        .post('/tag-model-test', {
          url: this.testURL,
          headline: this.testHeadline,
          tag_model: this.action.params.model,
          source_url: this.testSourceUrl,
          source_name: this.testSourceName,
          collection_names: this.testCollectionName
        })
        .then(response => {
          this.tagTestResult = response.data.data;
        });
    }
  }
};
</script>
