<template>
  <div>
    <div class="w-full">
      <div>
        <div class="flex space-x-2 items-start justify-between">
          <!-- List of Modes -->
          <div>
            <p class="font-semibold">Choose Scraping Mode</p>
            <div class="mt-2 flex-shrink-0 w-full max-w-md flex flex-wrap text-sm font-semibold gap-2">
              <label
                v-for="mode in allModes"
                :key="mode.value"
                class="border flex w-2/5 items-center space-x-1 p-2 bg-gray-800 rounded cursor-pointer duration-200"
                :class="
                  sourceMode == mode.value
                    ? 'bg-blue-900 text-blue-100 border-blue-700'
                    : 'bg-gray-800 border-transparent'
                "
              >
                <input type="radio" v-model="sourceMode" class="form-radio" :value="mode.value" />
                <span class="pl-1">{{ mode.name }}</span>
              </label>
            </div>
          </div>
          <div class="w-1/2 flex-grow">
            <div class="flex">
              <span class="font-semibold" :class="editingSource ? 'text-blue-600' : ''">{{
                editingSource ? 'Editing ' + sourceName + ' Details' : 'Enter Source Details'
              }}</span>
              <a v-if="editingSource" href="/sources/new" class="text-sm text-gray-300 font-medium ml-4 cursor-pointer"
                >Nevermind</a
              >
            </div>
            <div class="mt-2 relative rounded-md shadow-sm">
              <input
                required
                id="name"
                type="text"
                class="w-full"
                v-model="sourceName"
                placeholder="Pretty name for source"
              />
            </div>
            <div v-if="!nonUrlModes.includes(sourceMode)" class="mt-2 relative rounded-md shadow-sm">
              <input
                required
                id="url"
                type="text"
                class="w-full"
                v-model="sourceURL"
                placeholder="Enter the URL you want to scrape"
              />
            </div>
            <div v-if="sourceMode == 'JSON'">
              <p class="mt-1">
                <a href="https://goessner.net/articles/JsonPath/" target="_blank" class="text-blue-600 text-xs"
                  >Syntax guide</a
                >
              </p>
              <div class="mt-2 relative rounded-md shadow-sm">
                <input
                  required
                  v-model="sourceHeadlinePath"
                  type="text"
                  class="w-full"
                  placeholder="Headline path (ex: $.articles[*].title)"
                />
              </div>
              <div class="mt-2 relative rounded-md shadow-sm">
                <input
                  required
                  v-model="sourceLinkPath"
                  type="text"
                  class="w-full"
                  placeholder="Link path (ex: $.articles[*].url)"
                />
              </div>
              <div class="mt-2 relative rounded-md shadow-sm">
                <input
                  required
                  v-model="sourceDatePath"
                  type="text"
                  class="w-full"
                  placeholder="Date path (ex: $.articles[*].published)"
                />
              </div>
              <div class="mt-2 relative rounded-md shadow-sm">
                <input
                  v-model="sourceDateFormat"
                  type="text"
                  class="w-full"
                  placeholder="Date format (ex: %Y-%m-%d %H:%M:%S) -- optional"
                />
              </div>
            </div>
            <div v-if="sourceMode == 'HTML (Simple)'">
              <div class="mt-2 relative rounded-md shadow-sm">
                <input v-model="sourceXpath" type="text" class="w-full" placeholder="Xpath (Only for Research Scrap)" />
              </div>
            </div>
            <div v-if="sourceMode == 'Custom'">
              <div class="mt-2 relative rounded-md shadow-sm">
                <input v-model="sourceCustomClass" type="text" class="w-full" placeholder="ScraperName" />
              </div>
            </div>
            <div v-if="sourceMode == 'Twitter'">
              <div class="mt-2 flex items-center space-x-3 rounded-md shadow-sm">
                <input v-model="sourceTwitterId" type="number" class="w-full" placeholder="Twitter ID" />
                <span
                  class="whitespace-no-wrap font-semibold text-sm text-green-400 cursor-pointer hover:text-green-300"
                  @click="convertHandleToId()"
                  >Convert to ID</span
                >
              </div>
            </div>
            <div v-if="sourceMode == 'SEC'">
              <div class="mt-2 relative rounded-md shadow-sm">
                <input v-model="sourceCiks" type="text" class="w-full" placeholder="CIKs (comma-separated)" />
              </div>
            </div>
            <div v-if="sourceMode == 'SEC Filings'">
              <div class="mt-2 relative rounded-md shadow-sm">
                <input v-model="sourceCiks" type="text" class="w-full" placeholder="Keyword Search" />
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="flex items-center space-x-1 text-sm p-3 rounded cursor-pointer">
                <input type="checkbox" v-model="translate" class="form-checkbox mt-px" />
                <span class="font-semibold flex items-center">
                  <svg
                    class="w-4 h-4 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                    />
                  </svg>
                  <span>Translate?</span>
                </span>
              </label>
              <label for="urlparams" class="flex items-center space-x-1 p-3 text-sm font-semibold cursor-pointer">
                <input type="checkbox" id="urlparams" v-model="sourceNeedsUrlParams" class="form-checkbox" />
                <span>Needs URL Params?</span>
              </label>
              <label for="public" class="flex items-center space-x-1 p-3 text-sm font-semibold cursor-pointer">
                <input type="checkbox" id="public" v-model="sourcePublic" class="form-checkbox" />
                <span>Public?</span>
              </label>
            </div>
          </div>
        </div>

        <div class="mt-8">
          <span class="font-semibold">Choose Collections</span>
          <div class="mt-2 w-full flex flex-wrap gap-2">
            <label
              v-for="col in selectableCollections.slice().sort((a, b) => (a.name > b.name ? 1 : -1))"
              class="col-span-1 border font-semibold p-3 duration-200 rounded cursor-pointer"
              :class="
                editableSourceCollections.includes(col.id)
                  ? 'bg-blue-900 text-blue-100 border-blue-700'
                  : 'bg-gray-800 border-transparent'
              "
              :key="col.id"
            >
              <input v-model="editableSourceCollections" type="checkbox" class="form-checkbox" :value="col.id" />
              <span class="ml-1 text-sm">{{ col.name }}</span>
            </label>
          </div>
        </div>

        <div class="flex mt-8">
          <div v-if="!nonSchedulableModes.includes(sourceMode)" class="w-1/3 flex-shrink-0">
            <p class="font-semibold">Scrape Every:</p>
            <div class="flex items-center space-x-2 mt-2">
              <input v-model="schedulerValue" type="number" step="1" min="1" placeholder="1" class="w-32" />
              <select
                v-model="schedulerInterval"
                type="select"
                class="bg-gray-800 p-2 rounded border-none font-semibold w-32 form-select"
              >
                <option v-for="interval in schedulerIntervals" :key="interval" :value="interval">{{ interval }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex items-center space-x-4 mt-8">
          <button
            v-if="!nonSchedulableModes.includes(sourceMode) || (source && source.mode == 'SEC')"
            @click="testSource()"
            class="
              bg-green-600
              hover:bg-green-500
              duration-200
              px-3
              py-2
              rounded
              cursor-pointer
              text-sm
              font-semibold
              text-center
              w-full
            "
          >
            Run a Test Scrape (Do This First!)
          </button>
          <primaryButton
            :text="editingSource ? 'Save' : 'Create'"
            class="w-full"
            @click="editingSource ? updateSource(editingSource) : createSource()"
          ></primaryButton>
        </div>
      </div>
    </div>
    <div v-if="ranATestScrape" class="w-full mt-10">
      <span v-if="loading" class="font-semibold">Scraping...</span>
      <div v-else class="bg-gray-800 rounded">
        <p class="font-bold p-5 border-b border-gray-700">Test Scrape ({{ articles.length }} Headlines)</p>
        <div style="max-height: 600px" class="overflow-y-auto p-5 flex flex-col gap-y-3">
          <p v-for="article in articles" :key="article.id">
            <a :href="article.link" class="font-semibold text-sm" target="_blank">{{ article.headline }}</a>
            <span v-if="article.date" class="text-sm text-gray-300">
              <br />
              {{ article.date | createdAt }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import tableMixin from '../../mixins/table.js';

export default {
  props: {
    source: {
      type: String,
      default: ''
    },
    sourceCollections: {
      type: Array,
      default: () => []
    },
    collections: {
      type: Array,
      default: () => []
    }
  },
  mixins: [tableMixin],
  beforeMount() {
    if (new URLSearchParams(window.location.search).get('twitter') == 'true') {
      this.sourceMode = 'Twitter';
    }
  },
  mounted() {
    if (this.source) {
      this.toggleEditSource(this.source);
    }
  },
  computed: {
    selectableCollections() {
      if (this.sourceMode == 'Twitter') {
        return this.editableCollections.filter(c => c.name.includes('Twitter'));
      } else {
        return this.editableCollections.filter(c => !c.name.includes('Twitter'));
      }
    }
  },
  data() {
    return {
      collectionId: '',
      modeType: '',
      // editableSources: JSON.parse(JSON.stringify(this.sources)),
      editableSourceCollections: JSON.parse(JSON.stringify(this.sourceCollections || [])),
      editableCollections: JSON.parse(JSON.stringify(this.collections || [])),
      allModes: [
        { name: 'HTML', value: 'HTML (Simple)' },
        { name: 'HTML (Browser)', value: 'HTML (Full)' },
        { name: 'HTML (Speed)', value: 'HTML (Speed)' },
        { name: 'RSS', value: 'RSS' },
        { name: 'JSON', value: 'JSON' },
        { name: 'Twitter', value: 'Twitter' },
        { name: 'SEC CIK', value: 'SEC' },
        { name: 'SEC Keywords', value: 'SEC Filings' },
        { name: 'Email', value: 'Email' },
        { name: 'PDF', value: 'PDF' },
        { name: 'Custom', value: 'Custom' }
      ],
      loading: false,
      ranATestScrape: false,
      showSources: false,
      sourceName: '',
      sourceURL: '',
      sourceNeedsUrlParams: false,
      sourceMode: 'HTML (Simple)',
      sourcePublic: false,
      sourceHeadlinePath: '',
      sourceLinkPath: '',
      sourceDatePath: '',
      sourceDateFormat: '',
      sourceCustomClass: '',
      sourceTwitterId: '',
      sourceCiks: '',
      schedulerValue: '1',
      schedulerIntervals: ['seconds', 'minutes', 'hours'],
      schedulerInterval: 'minutes',
      articles: [],
      editingSource: '',
      translate: false,
      nonSchedulableModes: ['Twitter', 'Email', 'SEC'],
      nonUrlModes: ['Twitter', 'SEC', 'SEC Filings'],
      sourceXpath: null
    };
  },
  methods: {
    async testSource() {
      this.loading = true;
      this.ranATestScrape = true;
      this.articles = [];
      try {
        let response = await axios.post('/web-scrape', {
          url: this.sourceURL,
          mode: this.sourceMode,
          headline_path: this.sourceHeadlinePath,
          link_path: this.sourceLinkPath,
          date_path: this.sourceDatePath,
          date_format: this.sourceDateFormat,
          translate: this.translate,
          ciks: this.sourceCiks,
          custom_class: this.sourceCustomClass
        });
        this.articles = response.data.articles;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async createSource() {
      if (this.editableSourceCollections.length == 0) {
        alert('Please select at least one collection.');
        return;
      }
      await axios.post('/sources', {
        name: this.sourceName,
        url: this.sourceURL,
        mode: this.sourceMode,
        headline_path: this.sourceHeadlinePath,
        link_path: this.sourceLinkPath,
        date_path: this.sourceDatePath,
        date_format: this.sourceDateFormat,
        public: this.sourcePublic,
        collection_ids: this.editableSourceCollections,
        scheduler_value: this.schedulerValue,
        scheduler_interval: this.schedulerInterval,
        translate: this.translate,
        needs_url_params: this.sourceNeedsUrlParams,
        custom_class: this.sourceCustomClass,
        twitter_id: this.sourceTwitterId,
        ciks: this.sourceCiks,
        xpath: this.sourceXpath
      });
      window.location.href = this.sourceMode == 'Twitter' ? '/twitter_queries/accounts' : '/sources';
    },
    // deleteSource(id) {
    //   this.$dialog.confirm("Are you sure you want to delete this source?").then(() => {
    //     axios.delete('/sources/'+id)
    //   });
    // },
    async updateSource(source) {
      await axios.patch('/sources/' + source, {
        name: this.sourceName,
        url: this.sourceURL,
        mode: this.sourceMode,
        headline_path: this.sourceHeadlinePath,
        link_path: this.sourceLinkPath,
        date_path: this.sourceDatePath,
        date_format: this.sourceDateFormat,
        public: this.sourcePublic,
        collection_ids: this.editableSourceCollections,
        scheduler_value: this.schedulerValue,
        scheduler_interval: this.schedulerInterval,
        translate: this.translate,
        needs_url_params: this.sourceNeedsUrlParams,
        custom_class: this.sourceCustomClass,
        twitter_id: this.sourceTwitterId,
        ciks: this.sourceCiks,
        xpath: this.sourceXpath
      });
      window.location.href = this.sourceMode == 'Twitter' ? '/twitter_queries/accounts' : '/sources';
    },
    toggleEditSource(source) {
      this.editingSource = source.id;
      this.sourceName = source.name;
      this.sourceURL = source.url;
      this.editableSourceCollections = this.sourceCollections;
      this.sourceMode = source.mode;
      this.sourceHeadlinePath = source.headline_path;
      this.sourceLinkPath = source.link_path;
      this.sourceDatePath = source.date_path;
      this.sourceDateFormat = source.date_format;
      this.sourcePublic = source.public;
      this.schedulerValue = source.scheduler_value;
      (this.schedulerInterval = source.scheduler_interval), (this.translate = source.translate);
      this.sourceCustomClass = source.custom_class;
      this.sourceNeedsUrlParams = source.needs_url_params;
      this.sourceTwitterId = source.twitter_id;
      this.sourceCiks = Array.isArray(source.ciks) ? source.ciks.join(',') : source.ciks;
      this.sourceXpath = source.xpath;
    },
    async convertHandleToId() {
      const response = await axios.post('/twitter_ids', { handle: this.sourceName });
      this.sourceTwitterId = response.data.id;
    }
  }
};
</script>
