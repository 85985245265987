<template>
  <div>
    <div class="flex items-center space-x-2 text-sm">
      <div class="flex flex-shrink-0 space-x-2 items-center">
        <span class="text-xs">Min score:</span>
        <input class="form-input text-sm w-24" v-model="tagMinScore" type="number" placeholder="10" />
      </div>
      <select v-model="tagSubGroupId" class="form-select text-sm w-32 bg-gray-800 border-0">
        <option v-for="subgroup in tagSubGroups" :key="subgroup.id" :value="subgroup.id">{{ subgroup.name }}</option>
      </select>
      <input type="text" class="form-input text-sm w-full" v-model="tagName" placeholder="Bitcoin" />
      <svg
        v-if="tag && $root.store.userRole == 'admin'"
        @click="deleteTag"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="w-4 h-4 ml-4 cursor-pointer text-red-600 flex-shrink-0"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        />
      </svg>
    </div>
    <textarea
      required
      v-model="tagModel"
      class="mt-2 form-textarea text-sm w-full bg-gray-800 border-0"
      rows="5"
      placeholder='10: r"this is a test model", hello'
    ></textarea>
    <input
      type="text"
      class="form-input text-sm w-full mt-2"
      v-model="testHeadline"
      placeholder="Test a headline (r tag)"
    />
    <input type="text" class="form-input text-sm w-full mt-2" v-model="testURL" placeholder="Test a URL (u tag)" />
    <input
      type="text"
      class="form-input text-sm w-full mt-2"
      v-model="testSourceUrl"
      placeholder="Test a Source URL (p tag)"
    />
    <input
      type="text"
      class="form-input text-sm w-full mt-2"
      v-model="testSourceName"
      placeholder="Test a Source Name (t tag)"
    />
    <input
      type="text"
      class="form-input text-sm w-full mt-2"
      v-model="testCollectionNames"
      placeholder="Test a Collection Name (c tag)"
    />
    <div class="mt-3 flex items-center text-sm">
      <div v-if="tagTestResult.length > 0">
        <span class="text-gray-600 text-sm">Score:</span>
        <span class="text-lg font-bold">{{ tagTestResult[0].score }}</span>
      </div>
      <span
        @click="showRevisions()"
        v-if="$root.store.userRole == 'admin' && tag"
        class="ml-auto text-blue-400 font-semibold cursor-pointer"
      >
        Show Revisions
      </span>
      <span
        @click="testTagModel()"
        class="text-blue-400 font-semibold cursor-pointer px-3"
        :class="$root.store.userRole == 'admin' && tag ? '' : 'ml-auto'"
      >
        Test tag model
      </span>
      <Button type="primary" :disabled="creatingTag" size="sm" @click="tag ? editTag() : createTag()">
        <span class="font-semibold">{{ tag ? 'Save tag' : 'Add tag' }}</span>
      </Button>
    </div>
    <RevisionsHistoryModal
      v-if="showTagModelVersionModal"
      :show="showTagModelVersionModal"
      @close="showTagModelVersionModal = false"
      :resource="tag ? { id: tag.tag_models[0].id, model: 'TagModel' } : {}"
    />
  </div>
</template>

<script>
import axios from 'axios';
import RevisionsHistoryModal from '../modals/RevisionsHistoryModal.vue';
export default {
  props: {
    tag: {
      type: Object,
      default: null
    },
    activeSubGroup: {
      type: Object,
      default: null
    },
    tagSubGroups: {
      type: Array,
      default: () => []
    }
  },
  components: { RevisionsHistoryModal },
  data() {
    return {
      tagMinScore: 10,
      tagSubGroupId: '',
      tagName: '',
      tagModel: '',
      testHeadline: '',
      testURL: '',
      testSourceUrl: '',
      testSourceName: '',
      testCollectionNames: '',
      tagTestResult: {},
      creatingTag: false,
      showTagModelVersionModal: false
    };
  },
  mounted() {
    this.tagSubGroupId = this.tag ? this.tag.tag_subgroup_id : this.activeSubGroup.id;
    if (this.tag) {
      var tagModel = this.tag.tag_models[0];
      this.tagMinScore = tagModel.min_score;
      this.tagSubGroupId = this.tag.tag_subgroup_id;
      this.tagName = this.tag.name;
      this.tagModel = tagModel.rule;
    }
  },
  methods: {
    async createTag() {
      try {
        this.creatingTag = true;
        const response = await axios.post('/tags', {
          name: this.tagName,
          min_score: this.tagMinScore,
          model: this.tagModel,
          tag_subgroup_id: this.tagSubGroupId
        });
        this.$emit('createdTag', { tag: response.data.tag, tagGroupId: this.activeSubGroup.tag_group_id });
        this.resetTag();
        this.creatingTag = false;
      } catch {
        this.creatingTag = false;
      }
    },
    resetTag() {
      this.tagName = '';
      this.tagModel = '';
      this.testHeadline = '';
      this.testURL = '';
      this.testSourceUrl = '';
      this.testSourceName = '';
      this.testCollectionNames = '';
      this.tagTestResult = {};
    },
    async editTag() {
      await axios.patch('/tags/' + this.tag.id, {
        name: this.tagName,
        min_score: this.tagMinScore,
        model: this.tagModel,
        tag_subgroup_id: this.tagSubGroupId
      });
    },
    async deleteTag() {
      await this.$dialog.confirm(`Are you sure you want to delete ${this.tag.name}?`);
      await axios.delete('/tags/' + this.tag.id);
      this.$emit('deleteTag', {
        tagId: this.tag.id,
        tagGroupId: this.activeSubGroup.tag_group_id,
        tagSubgroupId: this.tag.tag_subgroup_id
      });
    },
    async testTagModel() {
      const response = await axios.post('/tag-model-test', {
        url: this.testURL,
        headline: this.testHeadline,
        tag_model: this.tagModel,
        source_url: this.testSourceUrl,
        source_name: this.testSourceName,
        collection_names: this.testCollectionNames
      });
      this.tagTestResult = response.data.data;
    },
    showRevisions() {
      this.showTagModelVersionModal = true;
    }
  }
};
</script>
