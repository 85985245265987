<template>
  <div>
    <div class="flex justify-between">
      <a href="/sources/new" class="bg-green-600 hover:bg-green-500 p-3 rounded font-semibold text-sm"
        >+ Add New Source</a
      >
      <input v-model="search" type="text" placeholder="Search sources" class="w-1/2" />
    </div>
    <div class="mt-10 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-700 font-semibold table-fixed">
            <thead class="bg-gray-900">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider whitespace-no-wrap"
                  v-for="(column, id) in columns"
                  :key="id"
                >
                  <span v-if="sortedColumn === id">
                    <svg
                      v-if="sortOrder === 'asc'"
                      class="w-4 h-4 flex-shrink-0 mr-1 inline"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <svg v-else class="w-4 h-4 flex-shrink-0 mr-1 inline" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span v-if="column.name === 'Collections'" class="cursor-auto">{{ column.name }}</span>
                  <span v-else class="cursor-pointer" @click="sort(sources, id)">{{ column.name }}</span>
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 whitespace-no-wrap text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  Last Scarpe
                </th>
                <th
                  scope="col"
                  class="px-9 py-3 text-left text-xs text-gray-300 bg-transparent uppercase tracking-wider"
                >
                  <div
                    @click="toggleDisabled()"
                    :class="`cursor-pointer ml-4 select-none ${filterDisabled ? 'text-white' : 'text-secondary'}`"
                  >
                    <input type="checkbox" class="form-checkbox text-sm mr-1" v-model="filterDisabled" />
                    <span> Filter Disabled Sources</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(source, sourceIdx) in sources"
                :key="source.id"
                :class="sourceIdx % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'"
              >
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  {{ source.id }}
                </td>
                <td class="px-6 py-2 text-xs">
                  {{ source.name }}
                </td>
                <td class="break-all px-6 py-2 text-xs text-left">
                  <template v-if="source.url && source.url.includes('http')">
                    <a :href="source.url" target="_blank">{{ source.url }}</a>
                  </template>
                  <template v-else>
                    <span>{{ source.url || source.twitter_id || source.ciks }}</span>
                  </template>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  {{ source.mode }}
                </td>
                <td class="px-6 py-2 text-xs">
                  {{ source.collections.map(c => c.name).join(', ') }}
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  <span>{{ source.scheduler_value }} {{ source.scheduler_interval }}</span>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-xs">
                  <span>{{ source.last_scrape | relativeTime }}</span>
                </td>
                <td class="px-6 py-2 whitespace-no-wrap text-right text-sm">
                  <span
                    v-if="source.is_enable_job && userRole == 'admin' && source.mode != 'SEC'"
                    @click="disableSource(source.id)"
                    class="text-sm text-red-500 hover:text-red-400 cursor-pointer"
                    >- Disable</span
                  >
                  <span
                    v-else-if="userRole == 'admin' && source.mode != 'SEC'"
                    @click="enableSource(source.id)"
                    class="text-sm text-green-500 hover:text-green-400 cursor-pointer"
                    >+ Enable</span
                  >
                  <a
                    :href="`/sources/${source.id}/edit`"
                    class="ml-4 text-sm text-blue-500 hover:text-blue-400 cursor-pointer"
                    >Edit</a
                  >
                  <span
                    v-if="userRole == 'admin'"
                    class="ml-4 text-sm text-red-500 hover:text-red-400 cursor-pointer"
                    @click="deleteSource(source.id)"
                    >Delete</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div>
      <pagination-bar :page="page" :per_page="per_page" :query_total="query_total" @pageClick="pageChange" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import tableMixin from '../../mixins/table';
export default {
  mixins: [tableMixin],
  props: ['userRole'],
  mounted() {
    this.page = parseInt(localStorage.getItem('sources.page')) || 1;
    this.searchHeadlines();
  },
  data() {
    return {
      sources: [],
      search: '',
      page: 1,
      per_page: 15,
      query_total: 0,
      search_delay: 150, // change this to reduce the number of requests sent to server
      awaitingSearch: false,
      columns: {
        id: { name: 'ID', align: 'text-left' },
        name: { name: 'Name', align: 'text-left' },
        url: { name: 'URL/CIKs/Twitter/Keywords', align: 'text-left' },
        mode: { name: 'Mode', align: 'text-left' },
        collections: { name: 'Collections', align: 'text-left' },
        scheduler_value: { name: 'Schedule', align: 'text-left' }
      },
      filterDisabled: false
    };
  },
  watch: {
    search() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.searchHeadlines();
          this.awaitingSearch = false;
          this.pageChange(1, -1);
        }, this.search_delay);
      }
      this.awaitingSearch = true;
    }
  },
  methods: {
    async searchHeadlines() {
      let response = await axios.get('/sources.json', {
        params: {
          search: this.search,
          page: this.page,
          per_page: this.per_page,
          disabled_flag: this.filterDisabled
        }
      });
      this.sources = response.data.all_data.data;
      this.query_total = response.data.all_data.total_items;
    },
    async enableSource(source_id) {
      await axios.post('/source_jobs', { id: source_id });
      const index = this.sources.findIndex(x => x.id == source_id);
      this.sources[index].is_enable_job = true;
      if (this.filterDisabled) this.sources = this.sources.filter(x => x.id !== source_id);
    },
    async disableSource(source_id) {
      await axios.delete(`/source_jobs/${source_id}`);
      const index = this.sources.findIndex(x => x.id == source_id);
      this.sources[index].is_enable_job = false;
    },
    async deleteSource(id) {
      await this.$dialog.confirm('Are you sure?');
      await axios.delete(`/sources/${id}`);
      this.sources = this.sources.filter(x => x.id !== id);
    },
    pageChange(idx, total_pages) {
      if (((idx >= 1) & (idx <= total_pages)) | (total_pages == -1)) {
        this.page = idx;
        localStorage.setItem('sources.page', idx);
        this.searchHeadlines();
      }
    },
    toggleDisabled() {
      this.filterDisabled = !this.filterDisabled;
      localStorage.setItem('sources.page', 1);
      this.page = 1;
      this.searchHeadlines();
    }
  },
  filters: {
    relativeTime(t) {
      if (t) {
        return moment(t).fromNow();
      }
      return null;
    }
  }
};
</script>
